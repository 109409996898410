.financial-modal {
  small {
    display: block;
    font-size: 15px !important;
  }

  .hbh-select-error {
    margin-top: 0px !important;
  }

  .hbh-date-error {
    margin-top: 0px !important;
  }
}


.stage-payment-section-wrapper {

  .ant-table-tbody tr.ant-table-row:hover > td {
    background-color: #434a4e !important;
  }

  .ant-table-row-level-0:last-child {
    .ant-table-cell {
      background: #434a4e;
    }
  }

  .pagination {
    margin-top: 40px;
  }

  .section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .control-buttons {
      display: flex;
      column-gap: 40px;
      align-items: center;

      .button {
        font-weight: 500;
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;

        &.blue {
          color: dodgerblue;
          column-gap: 15px;
        }
      }
    }
  }
}
@import '@assets/styles/variables';

.status-btn {
  background-color: red !important;
  color: white !important;
}

small {
  display: none;
}

.ant-tooltip-inner {
  white-space: break-spaces;
}

.hbh-text-error, .hbh-select-error, .hbh-date-error {
  font-size: 15px !important;
  margin-top: 20px!important;
  position: static !important;
}

.edit-button {
  background-color: #3A89F7;
}

.apply-button {
  background-color: green;
}

input {
  background-color: #fff;
  color: black !important;
  border: 1px solid $color-grey-middle-2;
}

.react-datepicker__tab-loop {
  position: fixed;
  z-index: 10000;
}

.hbh-date, .hbh-date[disabled] {
  font-size: 16px !important;
  opacity: 1 !important;
}

input:disabled {
  border: none;
}

.attachments-label {
  display: flex;
  align-items: center;
  margin-top: 50px
}

.attachments-icon {
  margin-right: 15px;
}

.attachments-list-wrapper {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;;
}

.attachments-list-element {
  margin: 5px 0;
  cursor: pointer;
  width: max-content;
  max-width: 50%;

  &:hover {
    text-decoration: underline;
  }
}

.buttons-container {
  display: flex;
  gap: 20px
}

.history-button {
  background-color: #616161;
}

@media (max-width: 1024px) {
  .ant-modal {
    width: 100% !important;
  }
}

.table-body {
  color: #000;
}

.modal-title {
  background-color: #0A6EB4;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #FFFFFF;
}

.ant-modal-body {
  background-color: #000;
}

.ant-modal-header {
  padding: 0 !important;
  border-bottom: none !important;
}

.ant-modal-close-x {
  color: #fff;
}

.history-table {
  .ant-table-thead .ant-table-cell {
    background-color: #262829;
    color: white;
    text-align: center;
  }

  .ant-table-cell {
    background-color: #9B9B9B;
    color: white;
    border: 1px solid black;
    border-bottom: none !important;
    text-align: center;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #000000 !important;
}

.focal-point-dropdown {
  width: 300px;
  color: #fff;
  margin-bottom: 20px;
}

.focal-point-wrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bold {
    font-weight: 800;
  }

  .buttons-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      width: 180px;
      height: 35px;
    }
  }

  .description {
    .assign-info {
      font-size: 18px;
    }
    .notification-info {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
    }
  }
}

.booking-details-container {

  .project-link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .buttons-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .btn {
      display: block;
      width: 150px;
      height: 30px;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

.phases-item-wrapper {
  margin-top: 25px;

  .phase-item-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap;
    row-gap: 30px;

    .details-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
    }

    .phase-title {
      width: 100%;
      white-space: pre-wrap;
      margin-right: auto;
      margin-left: 15px;
    }

    .phase-date-item {
      font-size: 14px;
      line-height: 17px;
    }

    .date-label {
      font-weight: 200;
    }
  }

  .complete-phase {
    display: flex;
    align-items: start;
    column-gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;

    .ant-checkbox-inner {
      background-color: #262829;
      border-color: #fff;
      font-size: 15px;
    }
  }

  .deliverables-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    row-gap: 5px;
  }

  .deliverables-title {
    font-weight: 300;
  }


  .phase-hr {
    border-color: #707070;
    margin: 30px 0;
  }

  .ant-checkbox-inner {
    background-color: #262829;
    border-color: #fff;
    font-size: 15px;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #262829 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #009815;
    border-color: #009815;
  }

  .deliverable-label {
    margin-top: 5px;
  }

  .ant-checkbox-wrapper {
    margin: 0 !important;
    align-items: start;
    color: #fff;
  }
}
@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;

.sf-wrapper {
  .sf-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: $margin;
    grid-row-gap: $margin;
    margin: 0 0 $margin;

    .sf-option {
      padding: 10px 12px;
      background-color: $color-grey-dark-2;
      cursor: pointer;
      transition: 0.2s all linear;
      min-height: 195px;
      border: 2px solid $color-nevada;

      &:hover {
        background-color: lighten($color: $color-grey-dark-2, $amount: 5%);
      }

      &.checked {
        border: 2px solid $color-lochmara;
      }

      .sf-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 0 $margin;
        height: 48px;

        .sf-input {
          flex-shrink: 0;
          margin: 4px;
          width: 18px;
          height: 18px;
          accent-color: $color-lochmara;
        }
      }

      .sf-icon {
        width: 30px;
        margin: $margin 0 calc(#{$margin} / 2);
      }

      .sf-description {
        color: $color-geyser;
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }

  .sf-button {
    background-color: $color-grey-dark-2;
    cursor: pointer;
    transition: 0.2s all linear;
    width: 100%;
    min-height: 195px;
    border: 2px solid $color-nevada;

    &:hover {
      background-color: lighten($color: $color-grey-dark-2, $amount: 5%);
    }

    &.checked {
      border: 2px solid $color-lochmara;
    }
  }
}

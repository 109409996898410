.chart-wrapper {
  .square {
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-right: 10px;
  }

  .breakdown-legend {
    display: flex;
    column-gap: 30px;
    margin: 20px 0 20px 5px;
  }

  .no-data-label {
    font-size: 13px;
    text-align: center;
    vertical-align: middle;
    margin-top: 15%;
  }

  .chart {
    height: 500px;
    width: 100%;
    pointer-events: auto;

    .breakdown-chart-tooltip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 5px 20px;
      column-gap: 10px;
    }
  }
}
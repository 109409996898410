.video-link {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: #fff;
  }

  span {
    color: #fff;
  }
}

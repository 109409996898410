.risk-container {
  .section-header {
    font-weight: bold;
    margin: 20px 0;
  }

  .ant-table-thead th {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .ant-table-row td {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .doughnut-chart-wrapper {
    height: 60%;

    .doughnut-chart {
      width: 180px;
      height: 300px;
      margin-top: 30px;
      margin-left: 10px;
    }
  }

  .widgets-wrapper {
    width: 46%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex-grow: 1;
  }

  .risk-widgets-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 30px;
  }

  .risk-effect-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .risk-traced {
    position: relative;
    height: 50%;

    .hbh-details-item-value {
      height: 100%;
    }

    .risk-value {
      font-size: 100px;
      font-weight: bold;
      position: absolute;
      bottom: 0;
      padding: 15px 10px;
    }
  }

  .excel-trigger {
    font-size: 13px;
    color: grey;
    font-weight: lighter;
    text-decoration: underline;
    cursor: pointer;
  }

  .edit-button {
    margin-top: 40px;
    padding: 10px 30px;
  }

  .radar-chart {
    width: 50%;
    min-width: 600px;
    flex-grow: 1;

    .radar-chart-legend {
      margin-top: 60px;
    }
  }
}
.ffa-list-wrapper {

  .ffa-page-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff;
    flex-wrap: wrap;
    row-gap: 20px;

    .hbh-select {
      min-width: 250px;
      height: auto;
    }

    .reset {
      align-self: center;
    }

    .dropdown-filters {
      display: flex;
      column-gap: 20px;
      flex-wrap: wrap;
      row-gap: 20px;
      z-index: 5;
    }
  }
}

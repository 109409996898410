@import 'src/assets/styles/variables';

.projects-overview-wrapper {
  .title {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .demand-dashboard-page-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .widget-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin: 30px 0;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 4%;

    &.topic-chart {
      background-color: #fff;
      padding: 30px;

      .label {
        font-size: 16px;
      }

      .chart {
        margin: 10px 0;
        width: 500px;
        height: 200px;
      }
    }

    .phase-table {
      width: 100%;
    }

    .country {
      height: 45vw !important;
    }

    .widget-wrapper {
      width: 100%;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      flex-grow: 1;
    }
  }

  .infrastructure-breakdown-section {
    background-color: #fff;
    margin-top: 50px;
    width: 100%;
    padding: 15px;
  }

  .hbh-container {
    background-color: rgba(255, 255, 255, 0.9);
    color: #000000;
  }

  .no-data-label {
    font-size: 13px;
    text-align: center;
    width: 100%;
  }

  .filter-dropdown-section {
    display: flex;
    column-gap: 50px;

    .reset {
      cursor: pointer;
      font-size: 14px;
      width: max-content;
      align-self: flex-start;
      color: $color-blue-primary;
      text-decoration: underline;
      flex-shrink: 0;
      margin-bottom: 4px;
      margin-right: auto;
    }

    .dropdown {
      width: 250px;
      height: 40px;

      .control-wrapper {
        height: 40px;
      }

      .control-icon {
        width: 20px;
        height: 20px;
        margin: 0 10px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .projects-stats-section {
    margin-top: 100px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .chart {
      display: flex;
      column-gap: 50px;
      width: 60%;

      .infrastructures-chart {
        width: 150px !important;
        height: 150px !important;
      }

      .chart-legend {
        width: 100%;
        font-size: 18px;

        .label {
          font-family: OpenSans-SemiBold, sans-serif;
          font-size: 18px;
        }

        .legend-wrapper {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          font-size: 16px;

          .legend-element {
            display: flex;
            column-gap: 20px;
            align-items: center;

            .value {
              font-family: OpenSans-SemiBold, sans-serif;
            }

            .legend-dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .projects-summary {
      display: flex;
      column-gap: 30px;

      .summary-wrapper {
        background-color: #fff;
        height: 78px;
        width: 184px;
        border-radius: 6px;
        padding: 10px;

        .bold,.value {
          font-weight: 600;
        }

        .value {
          font-size: 24px;
        }
      }
    }
  }
}

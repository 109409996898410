.field-assessment-list-container {
  .buttons-container {
    display: flex;
  }

  .field-assessment-video-link-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .country-page-icon {
    background-color: #08286c;
  }

  .view-button {
    width: 100%;
  }

  .generate-button {
    svg {
      fill: #fff;
      height: 20px;
      width: 20px;
    }
  }
}

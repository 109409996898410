.country-page-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
}

.country-configuration-wrapper {
  padding: 40px;

  .subheader {
    font-size: 12px;
    color: grey;
    margin-top: 5px;
  }

  .text-area {
    height: 150px;
  }

  .preview-image {
    width: 100%;
  }

  .highlights {
    margin-top: 30px;
  }

  .overview-text {
    white-space: pre-wrap;
    color: lightgray;
  }

  .buttons-container {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;

    button {
      height: 30px;
      min-width: 200px;
    }
  }

  .overview-detail-container {
    margin-top: 50px;
    display: block;
    width: 100%;
  }

  .audience-dropdown {
    width: 300px;
    margin-top: 30px;
  }

  .highlight-list {
    display: flex;
    column-gap: 30px;
    padding: 20px 20px 20px 5px;
    flex-wrap: wrap;

    .highlight-element {
      width: 35%;
      padding: 10px 20px 10px 10px;
      display: flex;
      flex-direction: column;
      min-width: 400px;

      &.borderd {
        border: 1px solid #000;
      }

      .preview {
        min-height: 500px;
        padding-bottom: 30px;
        background-color: #2C3235;
        margin-top: 20px;

        &.loading {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .summary {
          padding-left: 20px;
          color: lightgray;

          .summary-text {
            white-space: pre-wrap;
            margin-top: 20px;
          }

          .summary-description {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-top: 10px;
            white-space: pre-wrap;
            width: 100%;

            .pin-icon {
              min-width: 13px;
              height: auto;
            }

            .category-icon {
              width: 25px;
              height: auto;
              margin-left: -5px;
            }
          }

          .summary-title {
            font-weight: bolder;
            font-size: 20px;
            white-space: pre-wrap;
          }
        }

        .cover {
          background-size: cover;
          height: 200px;
          width: 100%;
          margin-bottom: 20px;
        }

        &.placeholder {
          background-color: #BEBEBE;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          flex-grow: 1;

          svg {
            width: 50px;
            height: 50px;
            margin-bottom: 30px;
          }
        }
      }

      .dropdown {
        margin: 20px 0;
      }

      .dropdown-label {
        font-size: 14px;
        color: #b8bfc4;
        margin-left: 5px;
        margin-bottom: 10px;
      }
    }
  }

  .story-cover-upload {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;

    .image-cover {
      .ant-upload-drag-container {
        height: 10px;
      }
    }

    .ant-upload-drag-container {
      margin-top: 20px;
      justify-content: center !important;
    }
  }
}

.showcase-info-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }
}

.hbh-container:has(div.showcase-information-section-wrapper) {
  width: 95vw !important;
}

.approve-confirmation {
  color: #fff;
  text-align: center;
  font-size: 18px;

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 30px;
    column-gap: 50px;

    button {
      min-width: 200px;
      height: 30px;
    }
  }
}

.showcase-information-section {

  .permission-checkbox {
    width: 50%;
    display: flex;;
    align-items: center;
    column-gap: 20px;
    margin-top: 40px;
    cursor: pointer;
  }

  .picture-display-mode-label {
    margin-bottom: 10px;
    color: #b8bfc4;
    font-size: 12px;
  }

  .project-code-label {
    font-weight: bold;
    cursor: pointer;
  }

  .content-label {
    margin: 20px 0;
  }

  .preview-image {
    width: 100%;
    max-height: 600px;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    .buttons-sections {
      display: flex;
      column-gap: 20px;
    }

    button {
      min-width: 150px;
      height: 30px;
    }
  }

  .hbh-details-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    grid-auto-flow: row !important;
  }

  .location-info {
    svg {
      fill: #fff;
      width: 20px;
      height: 20px;
    }
  }

  .story-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .download-link {
      text-decoration: underline;
      cursor: pointer;
    }

    .preview-button {
      background-color: #616161;

      &.active {
        background-color: #0288ff;
      }
    }
  }

  .story-inputs-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-top: 20px;
    row-gap: 20px;
    column-gap: 50px;

    .story-description-inputs {
      display: flex;
      min-width: 600px;
      flex-direction: column;
      row-gap: 20px;
      flex-grow: 1;
      white-space: break-spaces;

      .story-label {
        color: #b8bfc4;
        font-size: 12px;
        margin-bottom: 12px;
      }

      .words-counter {
        font-size: 14px;
        text-align: right;
        transition: 0.5s;

        &.error {
          color: #FF9494;
        }
      }

      .text-area {
        width: 100%;
        height: 165px;
        color: #000000;
      }
    }

    .input-label, .hbh-select-label {
      color: #fff;
      display: block;
      margin-bottom: 0;
      font-size: 14px;
      white-space: nowrap;;
      max-width: 100%;
    }
  }


  .field-label {
    transition: 0.5s;
    &.error {
      color: #FF9494;
    }
  }

  .buttons-container {
    margin-top: 20px;
    justify-content: flex-end;

    .button {
      width: 200px;
    }
  }

  .hbh-date {
    font-size: 14px !important;
  }

  .rs__placeholder {
    color: #000000;
  }

  .hbh-select-error, .hbh-date-error {
    display: none;
  }
}

.story-cover-upload {
  width: 40%;;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 30px;
}

.file-picker {

  max-height: 600px;

  .description-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
  }

  .ant-upload-wrapper {
    transition: 0.5s;
    &.disabled {
      .ant-upload-drag {
        filter: grayscale(1);
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  .ant-upload-disabled {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .ant-upload-list {
    margin-top: 20px;

    .ant-upload-list-item {
      .ant-upload-list-item-info:hover {
        background-color: transparent !important;
      }
    }
  }

  .ant-upload-list {
    color: #fff;

    :hover {
      background-color: transparent;
    }

    svg {
      fill: #fff;
    }
  }

  &.image-cover, .ant-upload {
    opacity: 1;
    &:hover {
      .selected-file-header {
        opacity: 1;
      }
    }

    .ant-upload {

      .ant-upload-disabled {
        padding-bottom: 0 !important;
        cursor: pointer;
      }
    }
  }

  .selected-file-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    opacity: 0;
    transition: 0.5s;
    background-color: rgba(0,0,0, 0.6);
    padding: 20px;
    position: absolute;
    width: 100%;
    height: auto !important;
  }

  img {
    width: 100%;
    max-height: 600px;
    height: 100%;
    object-fit: cover;
  }

  .ant-upload.ant-upload-drag {
    background-color: transparent !important;
    border: 1px solid #ffff;
    border-radius: 8px;
    height: 600px;
    overflow-y: hidden;
    margin-top: 8px;

    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      div {
        height: 100%;
      }

      .ant-upload-text {
        color: #fff;

        &.error {
          color: #FF9494;
        }

        &.title {
          font-size: 14px;
        }

        &.subtitle {
          font-size: 12px;
        }

        &.heading {
          text-align: left;
          margin-left: 20px;
        }
      }
    }
  }
}

.assessment-overview-summary-container {
  .summary-field {
    display: flex;
    align-items: center;
    column-gap: 40px;
    margin-bottom: 10px;

    .work-items-done-container {
      width: 75%;
    }

    &.work-item {
      align-items: flex-start;

      .work-item-element {
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 1px solid #A0A6AC;
        margin-bottom: 15px;

        &:last-child {
          border: none;
        }
      }
    }
  }

  .section-label {
    width: 160px;
  }
}
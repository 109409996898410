.update-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-left: 2px solid #fff;
  border-radius: 3px;
  padding-left: 20px;
  position: relative;

  &.no-border {
    border-left: none;
    padding: 20px;
  }

  .text-info-wrapper {
    width: 60%;
  }

  .dot {
    width: 16px;
    height: 16px;
    background-color: #059200;
    border-radius: 50%;
    position: absolute;
    left: -9px;
  }

  .status-header {
    font-size: 18px;
    line-height: 1;

    .subtitle {
      margin-top: 10px;
    }

    .project-id {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .edit-btn {
      cursor: pointer;
      margin-left: 10px;
      color: #818181;
      font-size: 14px;
      text-decoration: underline;
      font-style: italic;
    }
  }

  .related-info {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 300;;
    display: flex;
    column-gap: 20px;

    .related-value {
      font-weight: normal;
    }
  }

  .title {
    font-size: 18px;
    margin: 20px 0 16px 0;
  }

  .description {
    font-size: 17px;
  }

  .image-container {
    width: 35%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 10px;

    .ant-image {
      width: 20%;
      height: auto;
    }

    .ant-image-mask-info {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div:first-child {
      width: 100% !important;
      height: auto;
      margin-bottom: 10px;
    }
  }

  .hr {
    border: none;
    height: 1px;
    width: 100%;
    background-color: grey;
    margin: 30px 0 30px 0;
  }
}

.empty-list-message {
  text-align: center;
  padding: 50px 0;
  font-weight: 300;
  font-size: 18px;
}

.update-box-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
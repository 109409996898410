@import 'src/assets/styles/variables';

.projects-share-access-modal {
  padding: 30px 10px 0 10px;

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    .borderless {
      border: none;
    }

    button {
      padding: 16px 32px 16px 32px;
    }
  }

  .title-section {
    background-color: #434A4E;
    padding: 16px;
    margin: 24px 0 16px 0;

    .title-value {
      font-size: 16px;
    }
  }

  .modal-header {
    font-size: 24px;
    color: #fff;
  }
}


.customer-projects-list-wrapper {
  background: url("../../assets/img/background2.jpg");
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  background-blend-mode: multiply !important;
  min-height: 82vh;
  color: #F2F4F8;
  display: flex;
  justify-content: center;


  @media (max-width: $breakpoint-lg) {
    padding: 100px 0 !important;
    width: 100% !important;
  }

  .project-section-wrapper {
    width: 90%;
    background-color: rgba(44, 50, 53, 0.7);
    padding: 40px;
    color: #F2F4F8;
    min-height: 400px;

    @media (max-width: $breakpoint-md) {
      padding: 0;
    }

    @media (max-width: $breakpoint-lg) {
      width: 100%;
      flex-grow: 1;
    }

    .project-update-list {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      width: 100%;
      margin-top: 24px;
      font-size: 15px;

      .project-update-element {
        display: flex;
        background-color: #434A4E;
        padding: 24px;


        flex-direction: row-reverse;
        justify-content: space-between;

        @media (max-width: $breakpoint-xl) {
          flex-direction: column;
          row-gap: 30px;
        }

        .update-picture {
          max-width: 45%;
          min-height: 200px;
          height: 100%;

          @media (max-width: $breakpoint-xl) {
            max-width: 100%;
          }
        }

        .update-text-info-container {

          @media (max-width: $breakpoint-xl) {
            width: 100%;
          }

          width: 50%;
          text-align: left;
        }
      }

      .download-section {
        display: flex;
        column-gap: 8px;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        margin-top: 40px;
      }

      .update-description-section {
        margin-top: 40px;
        font-size: 16px;

        .update-title {
          margin-bottom: 8px;
          font-size: 20px;
        }
      }

      .update-info {
        margin: 8px 0;

        .update-label {
          color: #C1C7CD;
        }

        .project-name {
          font-size: 16px;
          font-weight: bolder;
          text-decoration: underline;
        }
      }
    }

    .projects-list-container {

      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;

      .project-list-wrapper {
        width: 48%;
        background-color: #434A4E;
        margin-top: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: $breakpoint-xl) {
          width: 100%;
          flex-grow: 1;
        }

        .controls-section {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 40px;

          .section {
            display: flex;
            align-items: center;
            column-gap: 8px;
            cursor: pointer;
          }
        }

        .project-details-info {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          column-gap: 8px;
          margin-top: 40px;

          .project-details-element {
            display: flex;
            align-items: center;
            column-gap: 24px;

            .project-details-label {
              font-size: 14px;
              color: #C1C7CD;
              min-width: 160px;
            }

            .project-details-label {
              font-size: 14px;
              color: #F2F4F8;
            }
          }
        }

        .project-name {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .project-description {
          font-size: 14px;
        }
      }
    }

    .projects-number {

      @media (max-width: $breakpoint-md) {
        padding-top: 20px;
        text-align: center;
      }

      font-size: 20px;
      font-weight: 400;
    }
  }
}
@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;

.radio-option-field {
  display: block;
  margin: 10px auto;

  .radio-option-field-input {
    display: none;
  }

  .radio-option-field-item {
    width: 100%;
    height: $input-height;
    border: 2px solid $color-oslo;
    background-color: $color-grey-dark-2;
    color: $color-catskill;
    padding: 7px 10px;
    cursor: pointer;
    transition: 0.2s all linear;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: lighten($color: $color-grey-dark-2, $amount: 4%);
    }

    svg {
      fill: $color-chateau;
      opacity: 0;
      transition: 0.2s opacity linear;
    }
  }

  .radio-option-field-input:checked + .radio-option-field-item {
    background-color: $color-nandor;
    border-color: $color-chateau;

    svg {
      opacity: 1;
    }
  }
}

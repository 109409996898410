@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;
$field-font-size: 16px;

.bf-review-section {
  padding: $margin $margin 0;

  .bf-review-title {
    color: $color-catskill;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-catskill;
    cursor: default;
    margin: 0 0 calc(#{$margin} * 1.5);

    .bf-review-title-button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;

      svg {
        fill: $color-catskill;
      }

      &:hover {
        color: $color-white;
      }
    }
  }

  .bf-review-row {
    display: flex;
    margin: $margin 0;
    line-height: 16px;
    align-items: center;

    .bf-review-label {
      width: 30%;
      color: $color-loblolly;
      font-size: 14px;
      padding: 3px 20px 0 0;
      flex-shrink: 0;
    }

    .bf-review-value {
      width: 70%;
      color: $color-catskill;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 20px;
    }

    .bf-review-list {
      padding: 0 0 0 20px;
      list-style: disc;
      li {
        margin: 0 0 5px;
      }
    }

    .bf-review-locations {
      .bf-review-location {
        display: flex;
        align-items: center;
        margin: 0 0 5px;

        .bf-review-location-icon-wrapper {
          position: relative;
          flex-shrink: 0;

          .bf-review-location-count {
            position: absolute;
            top: 6px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: $color-white;
            cursor: default;
          }

          .bf-review-location-icon {
            width: 32px;
            height: 32px;
            fill: $color-nevada;
          }
        }

        .bf-review-location-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0 15px 0 5px;
        }
      }
    }

    .bf-review-location-button {
      background: none;
      border: 0;
      font-size: 14px;
      padding: 0;
      margin: 10px 0;
      cursor: pointer;
      text-decoration: underline;
    }

    .bf-review-location-arrow {
      fill: $color-white;
      margin: 0 5px;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.bf-review-terms {
  padding: $margin $margin 0;

  .bf-review-terms-text {
    color: $color-white;
    line-height: 1;
    display: block;
    margin: 6px 0 0;
    font-size: 14px;
  }

  .bf-review-terms-link {
    font-size: 14px;
    color: $color-loblolly;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

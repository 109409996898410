@import '../../../../assets/styles/variables';

.guidance-form-wrapper {
  color: #fff;

  .form-spinner {
    margin: 0 auto;
    width: 100%;
    padding: 40px 0;
  }

  .react-datepicker-popper {
    width: 400px;
  }

  .rs__value-container {
    flex-wrap: nowrap !important;
  }

  .rs__multi-value {
    min-width: 20% !important;
  }

  .form-container {
    width: 100%;
    display: flex;
    column-gap: 40px;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 25px;

    &.disabled {
      display: none;
    }

    .hbh-select-error {
      display: none;
    }

    .hbh-date-error {
      display: none;
    }

    .dropdown {
      width: 100%;
    }

    .text-area {
      width: 100% !important;
      display: flex;
      flex-direction: column;

      .error {
        position: static !important;
      }

      textarea {
        color: #000000;
        height: 100px;
        resize: none;
      }
    }

    fieldset {
      display: flex;
      column-gap: 10px;
      align-items: center;

      .info-icon {
        margin-right: 5px;
        svg {
          fill: #1A6EB1;
        }
      }

      .radio-element label {
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        width: auto;
        column-gap: 10px;
      }
    }

    .input-wrapper {
      width: 45%;
      flex-grow: 1;

      .error {
        position: absolute;
        color: red;
        margin-top: 5px;
        font-size: 12px;
      }

      small {
        display: none;
      }

      .input {
        height: 30px;
        width: 100%;
      }

      .numeric-input {
        height: 30px;
      }

      .date-picker {
        font-size: 14px !important;
      }

      .ant-picker {
        height: 30px;
        width: 100%;
      }

      .input-label, .hbh-select-label {
        color: #fff;
        display: block;
        margin-bottom: 0;
        font-size: 14px;
        white-space: nowrap;;
        max-width: 100%;
      }
    }
  }

  .infrastructure {
    margin-top: 20px;
    overflow: hidden;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    width: 100%;

    .infrastructure-label {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    margin: 0 auto;

    .hr {
      width: 55%;
    }
  }

  .buttons-section {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-lg) {
      flex-direction: column;
      row-gap: 20px;

      .button {
        width: 100% !important;
      }
    }

    .button {
      width: 48%;
      height: 50px;
      border-radius: 5px;

      .icon {
        display: flex;
        align-items: center;
      }
    }
  }
}

.preliminary-tooltip {

  .ant-tooltip-content {
    background-color: #000000;
  }
}
.location-picker-modal {
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;

  .location-picker-wrapper {
    margin: 10px 0;
  }

  .disabled-button-tooltip {
    background-color: #ffffff;
  }

  .location-value {
    font-weight: bold;
  }

  .location-picker-map {
    width: 50%;
  }

  .infrastructure-picker {
    margin: 20px auto;
    width: 40%;

    .ant-input, .rs__control {
      border-radius: 6px;
    }

    .title {
      margin-bottom: 10px;
    }

    label {
      margin: 13px 0;
      display: block;
    }
  }

  .buttons-wrapper {
    background-color: black;
    margin: 32px auto;

    .confirm-button {
      width: 230px;
      height: 40px;
      font-size: 21px;
      background-color: #008E1C;
    }
  }
}
@import "src/assets/styles/variables";

.customer-request-details-wrapper {
  background: url("../../assets/img/background2.jpg");
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  background-blend-mode: multiply !important;
  min-height: 82vh;
  color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 20px;
  padding: 100px 0;

  .crumb-active {
    cursor: pointer;
  }

  .ant-breadcrumb {
    margin-bottom: 40px;
  }

  .ant-breadcrumb-link {
    color: #fff;
    font-size: 14px;
  }

  .ant-breadcrumb-separator {
    color: #fff;
  }

  .content {
    width: 50%;

    @media (max-width: $breakpoint-xl) {
      width: 95%;
    }

    .request-details-content {
      background-color: #2c3235;
      width: 100%;
      font-size: 18px;
      padding: 42px;
      margin-bottom: 16px;

      .details-list-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .details-element {
          display: flex;
          align-items: center;
          column-gap: 24px;

          .dotted-list {
            padding-left: 24px;
            list-style: outside;
            margin-bottom: 10px;
          }

          .location-details {
            .location-element {
              display: flex;
              column-gap: 8px;
              align-items: center;

              &:not(:last-child) {
                margin-bottom: 8px;
              }

              svg {
                fill: $color-white;
                width: 20px;
                height: 20px;
              }
            }
          }

          .details-label {
            display: block;
            width: 180px;
            white-space: pre-wrap;
            font-size: 14px;
          }

          .details-value {
            text-align: left;
          }

          .details-label {
            font-size: 16px;
          }
        }
      }

      .details-title {
        font-size: 20px;
        margin-bottom: 32px;
      }
    }
  }

  .request-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
  }
}

.radar-chart-wrapper {
  .radar-chart-legend {
    display: flex;
    column-gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .legend-element {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .indicator {
        display: inline-block;
        width: 25px;
        height: 20px;
        background-color: red;
      }
    }
  }
}
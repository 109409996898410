.redirect-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .redirect-box {
    width: 900px;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 22px;
      margin-bottom: 30px;
    }

    a {
      font-size: 18px;
      text-align: center;
    }
  }
}

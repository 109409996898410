@import "../../assets/styles/variables";
@import "@assets/styles/mixins";

.risk-border {
  border: 2px solid;

  &.low {
    border-color: #1e7dbc;
  }

  &.medium {
    border-color: #f5a623;
  }

  &.high {
    border-color: #d0021b;
  }
}

.border-value {
  border: 1px solid #fff;
  color: lightgray;
  border-radius: 5px;
  white-space: nowrap;
  padding: 3px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  background-size: cover !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  background-blend-mode: multiply !important;
  padding-bottom: 100px;

  .video-link {
    display: flex;
    width: 85%;
    margin-top: 15px;
    justify-content: flex-end;

    @media (max-width: $breakpoint-lg) {
      margin-top: 60px;
      justify-content: flex-start;
      margin-left: 10px;
    }
  }
}

.guidance-wrapper {
  width: 70%;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 40px auto 0;
  height: 870px;
  padding: 20px;
  color: #fff;

  @media (max-width: $breakpoint-xl) {
    width: 80% !important;
    height: auto;

    .guidance-element {
      flex-grow: 1;
    }
  }

  @media (max-width: $breakpoint-lg) {
    width: 100% !important;
    height: auto;
    margin-top: 100px;

    .guidance-element {
      flex-grow: 1;
    }
  }

  .guidance-description {
    margin: 20px 0;

    .description {
      font-size: 12px;
      font-weight: lighter;
    }

    .bold {
      font-weight: bold;
    }
  }

  .list-section {
    display: flex;
    flex-direction: column;

    .activity-contact-button {
      margin: 10px auto;
      @include applyButtonColor(#333333);
      &.full-width {
        margin-top: 30px;
        width: 100%;
      }
    }

    .guidance-list {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      justify-items: center;
      gap: 10px;
      padding: 20px 3%;
      column-gap: 70px;
      row-gap: 30px;
      max-height: 380px;
      overflow: hidden;
      overflow-y: auto;

      .guidance-element {
        width: 200px;
        height: 100px;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 5px;
        padding: 5px;
        transition: 0.5s;
        cursor: pointer;
        overflow: hidden;

        &.disabled {
          opacity: 0.3;

          &:hover {
            background-color: transparent;
          }
        }

        &:hover {
          background-color: #3383ee;
        }
      }
    }
  }

  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    row-gap: 30px;
    align-items: center;

    @media (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: flex-start;

      > div,
      > span {
        width: 100% !important;
        flex-grow: 1;
      }
    }

    .complexity-suffix {
      background-color: #fff;
      padding: 2px 10px;
      color: red;
      text-transform: capitalize;
    }

    .clear-filters-button {
      height: 32px;
    }

    .ant-select-selection-placeholder {
      font-size: 16px !important;
    }

    .custom-tag-paragraph {
      color: #fff;
      font-size: 16px !important;
    }

    .hbh-select-container {
      width: 32%;

      .hbh-select {
        width: 100%;

        .rs__placeholder,
        .rs__single-value {
          color: #fff;
          font-size: 16px;
          white-space: nowrap;
        }

        .rs__input {
          color: #fff !important;
        }

        svg {
          fill: #fff;
        }

        .rs__control {
          background-color: #333333;
          border: 1px solid #fff;
          color: #fff;
        }
      }
    }

    .search-input {
      width: 66%;

      .ant-input-affix-wrapper,
      .ant-input {
        color: #fff !important;
        background-color: #333333;
      }

      .anticon-close-circle {
        svg {
          fill: #fff;
        }
      }

      .ant-input-group-addon {
        display: none;
      }
    }

    .filter-dropdown {
      width: 32%;

      .help-icon {
        margin-left: 10px;
        svg {
          width: 14px;
          height: 14px;
        }
      }

      .ant-select-selection-item {
        color: #fff;
      }

      .ant-select-selection-search-input {
        display: none !important;
      }

      .ant-select-arrow {
        color: #fff;
      }

      .ant-select-selection-placeholder {
        color: #fff;
        font-size: 12px;
      }

      .ant-select-selector {
        background-color: #333333;
      }
    }
  }

  .filters-description {
    margin-top: 15px;
  }

  .paragraph {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    margin: 5px 0;

    &.bold {
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.risk-dropdown {
  padding: 0 !important;
  z-index: 10 !important;
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
}

.link-box {
  background-color: #333333;
  border-radius: 3px;
  padding: 5px 10px;
  text-align: center;
  cursor: default;
  margin: 30px auto 10px;
  width: 100%;
}

@import '../../../../assets/styles/variables';

.guidance-modal {
  padding-bottom: 0 !important;
  z-index: 100;
  max-height: 80vh;
  color: #fff !important;

  @media (max-width: $breakpoint-xxl) {
    width: 60% !important;
  }

  @media (max-width: $breakpoint-xl) {
    width: 70% !important;
  }

  @media (max-width: $breakpoint-lg) {
    width: 100% !important;
  }

  .ant-modal-content {
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #1c1c1cfa;
    border-collapse: collapse;
    height: 100%;
  }

  .ant-modal-body {
    background: none;
  }
}
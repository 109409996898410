@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;
$field-font-size: 16px;

.booking-form-page {
  background: url("../../assets/img/background5.jpg");
  background-position: center;
  background-size: 120%;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
  min-height: calc(100vh - 185px);
  background-attachment: fixed;

  .hbh-date {
    height: $input-height;
    padding: 5px 10px;
    color: $color-shark !important;
    border: 0;

    &:disabled {
      background-color: $color-grey-middle-5;
    }
  }

  .hbh-text-field {
    height: $input-height;
    font-size: $field-font-size;
    padding: 5px 10px;
    color: $color-shark !important;
    border: 0;

    &.dark-input {
      background-color: $color-grey-dark-2;
      color: $color-catskill !important;
      &::placeholder {
        color: $color-grey-middle-1;
      }
    }

    &:disabled {
      background-color: $color-grey-middle-5;
    }
  }

  .hbh-text-area-field {
    height: 100px;
    background-color: $color-white;
    font-size: $field-font-size;
    color: $color-shark;
    padding: 7px 10px;
    border: 0;
    line-height: 1.3;
  }

  .hbh-select {
    .rs__control {
      height: $input-height;
      min-height: $input-height;
      font-size: $field-font-size;
      border: 0;
    }

    .rs__value-container {
      padding: 2px 7px;
    }

    &.rs--is-disabled {
      .rs__control {
        background-color: $color-grey-middle-5;
      }
    }
  }

  .hbh-select-error,
  .hbh-date-error {
    display: none;
  }

  .react-datepicker__monthPicker {
    display: flex;
  }
}

.rs__menu {
  font-size: 16px;
}

.bf-container {
  color: $color-catskill;
  background-color: $color-shark;
  margin: $margin auto;
  max-width: 1200px;
  padding: calc(#{$margin} * 2);
  display: flex;
  font-size: $field-font-size;

  .bf-navigation {
    width: 30%;

    .navigation-stepper-container {
      padding: 0;
    }

    .navigation-title {
      display: none;
    }
  }

  .bf-main-title {
    font-size: 20px;
    margin: 0 0 $margin;
    text-align: center;
    border-bottom: 1px solid $color-white;
    padding: 0 0 5px;
  }

  .bf-content {
    width: 70%;
  }

  .bf-row {
    display: flex;
    gap: $margin;
    margin: $margin auto calc(#{$margin} * 2);

    .bf-field {
      width: 50%;
      margin: 0;
    }
  }
}

.bf-user-email {
  margin: 0 0 calc(#{$margin} * 2);
  color: $color-loblolly;
  font-size: 14px;
}

.assessment-progress-summary-container {

  width: 100%;

  .summary-field {
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
    margin-bottom: 10px;

    &.estimated-cost-table {
      flex-direction: column;

      .percentage {
        column-gap: 10px;
      }

      .small-input {
        width: auto;
      }

      .amount {
        text-align: right;
      }

      .assessment-progress-step-container {
        width: 100%;
        margin: 20px 0;
      }

      .section-value {
        width: 100%;
        margin-top: 16px;
      }
    }
  }

  .section-label {
    width: 160px;
  }
}
.assessment-publish-step-container {

  .buttons-container {
    margin-right: 0;
  }

  .submission-header {
    border-bottom: 1px solid #A0A6AC;
    padding-bottom: 12px;

    .submission-title {
      color: #F2F4F8;
      font-size: 20px;
    }
  }

  .section-label {
    font-size: 14px;
    color: #C1C7CD
  }

  .section-value {
    font-size: 16px;
    color: #F2F4F8;
    overflow-wrap: break-word;
    max-width: 70%;
  }

  .assessment-step-section {
    margin: 40px 0;
    .section-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      border-bottom: 1px dotted #A0A6AC;
      padding-bottom: 20px;
      font-size: 16px;
      color: #F2F4F8;

      .section-edit-button {
        display: flex;
        align-items: center;
        column-gap: 8px;
        fill: #35A5F3;
        color: #35A5F3;
        cursor: pointer;
      }

      .section-title {
        svg {
          width: 20px;
          height: 20px;
          fill: #35A5F3;
        }

        display: flex;
        align-items: center;
        column-gap: 12px;
      }
    }
  }
}
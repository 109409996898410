.location-picker-wrapper {
  display: flex;
  justify-content: space-around;;
  overflow: hidden;
}


.locations-list {
  margin-top: 13px;
  overflow-y: auto;
  max-height: 300px;
  overflow-x: hidden;

  .location-item {
    display: flex;
    margin-top: 15px;
    align-self: flex-start;

    .delete-icon {
      width: 14px;
      height: 14px;
      fill: red;
      cursor: pointer;
      margin-top: 1px;
      margin-left: 10px;

      &.hidden {
        display: none;
      }
    }

    .description {
      font-size: 12px;
      width: 90%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      white-space: break-spaces;
    }
  }
}

.pick-location-button {
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: #02B019;
  padding-right: 5px;

  &.hidden {
    display: none;
  }

  .icon {
    margin-left: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.marker-icon {
  width: 15px;
  height: 15px;
  background-color: #0A6EB4;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  border: 1px solid #fff;
  margin-right: 5px;

  &.list {
    margin: 0 10px;
  }

  .icon-value {
    margin-left: 0.5px;
  }
}
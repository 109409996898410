@import 'src/assets/styles/variables';


.cms-menu {
  @media (max-width: $breakpoint-lg) {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
  }
}
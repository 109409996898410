@import 'src/assets/styles/variables';

.cms-navigation {
  width: 100%;
  height: 40px;
  background-color: black;
  color: #fff;
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
  align-items: center;
  z-index: 1000;

  @media (max-width: $breakpoint-lg) {
    display: none;
  }

  .menu-link {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 0 15px;
    height: 100%;
    justify-content: center;
    color: #fff;
    position: relative;

    &:hover {
      background-color: #095d93;
    }

    &.home {
      background-color: #65A6D9;
    }

    .home-icon {
      position: absolute;
      left: 0;
      margin-left: 15px;
      display: flex;
      align-items: center;

      .icon {
        width: 20px;
        height: auto;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #fff;
        margin-left: 15px;
        margin-top: 3px;
        transform: rotate(90deg);
      }
    }
  }

  .active {
    background-color: #1A6EB4;
  }

  .icon {
    margin-right: 10px;
    height: auto;
  }
}
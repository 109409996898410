@import 'src/assets/styles/variables';

.mobile-nav-wrapper {

  @media (max-width: $breakpoint-lg) {
    display: block;
  }

  display: none;
  position: absolute;
  z-index: 1000000;
  width: 100%;

  .mobile-nav-drawer {
    color: #fff;

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .navigation-button {
      width: 125px;
      margin: 20px 0 0;

      &.nav {
        display: flex;
      }
    }

    .icon {
      fill: #115287
    }

    .menu-element {
      display: flex;
      align-items: center;
      column-gap: 20px;
      width: max-content;
      padding-right: 20px;

      &.active {
        border-bottom: 1px solid #fff;
        padding-bottom: 5px;
      }
    }

    .logout-button {
      font-size: 16px;
      margin-top: 20px;
    }

    .ant-drawer-mask {
      width: 100vw;
      height: 100vh;
    }

    .ant-drawer-content {
      max-height: 50vh;
      height: auto;
    }

    .ant-drawer-body {
      background-color: #0F497C;
    }

    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
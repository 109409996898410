@import 'src/assets/styles/variables';
.country-map {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  color: #101010;
  font-size: 15px;
  overflow: hidden;
  padding: 15px 20px 30px;
  p {
    margin-bottom: 20px;
  }

  .mapboxgl-popup-tip {
    border-top-color: rgba(0,0, 0, 0.8) !important;
  }

  .mapboxgl-popup-content {
    background-color: rgba(0,0, 0, 0.9);
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    .tooltip {
      color: #fff;
      font-size: 14px;
    }
  }

  @media (max-width: $breakpoint-xl) {
    width: 100%;
  }
}
.projects-list {

  .project-list-video-link-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff;
    flex-wrap: wrap;
    row-gap: 20px;

    .hbh-select {
      min-width: 150px;
      height: auto;
    }

    .reset {
      align-self: center;
    }


    .project-input-label {
      font-size: 12px;
    }

    .project-dropdown-filters {
      display: flex;
      column-gap: 20px;
      flex-wrap: wrap;
      row-gap: 20px;
      z-index: 5;
    }
  }

  .table {
    .phase-cell {
      padding: 0 10px;
      border: 1px solid;
      border-radius: 3px;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.phase-0 {
        border-color: #074F57;
      }

      &.phase-1 {
        border-color: #75B3B7;
      }

      &.phase-2 {
        border-color: #B4EBCA;
      }

      &.phase-3 {
        border-color: #077187;
      }

      &.phase-4 {
        border-color: #23F0C7;
      }

      &.phase-5 {
        border-color: #26B37A;
      }
    }
  }

  .project-input {
    width: 100%;
    margin-bottom: 10px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.new-project-button {
  background-color: #008612;
  border-color: #008612;

  &:hover {
    background-color: #008612;
    border-color: #008612;
  }
}

.gant-task-title {

  .ant-tooltip-content {
    width: max-content !important;
  }
}

.project-plan-wrapper {
  width: 100%;
  height: 70vh;
  background-color: #fff;

  .gant-task-title-open {
    display: block;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .gantt-tooltip {
    background-color: #fff;
    max-width: 500px;
    padding: 15px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    font-family: 'Open Sans', sans-serif !important;

    div {
      margin: 3px 0;
    }
    strong {
      font-weight: 800;
    }
  }

  .gantt-control-buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 1px;
    background-color: black;
    color: #fff;
    margin-bottom: 5px;

    .control-button {
      border-radius: 0;
      background-color: #3988F7;
      opacity: 0.3;

      &.active {
        opacity: 1;
      }

      &.name-toggle {
        margin-right: auto;
      }
    }
  }
}
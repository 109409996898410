@import "src/assets/styles/variables";

.intl-tel-input.allow-dropdown {
  width: 100%;

  .intl-tel-element {
    width: 100%;
    height: 40px;
    outline: none;
  }

  .country-list {
    border: 0;
    font-size: 14px;
  }

  .selected-flag {
    border-right: 1px solid $color-grey-light-3;
    height: 20px;
    margin: 10px 0 0;
    display: flex !important;
    background-color: transparent !important;
    outline: none;

    .selected-dial-code {
      display: block;
      padding: 0 0 0 5px;
      cursor: default;
      height: 26px;
    }

    .arrow {
      order: -1;
      margin: 0 7px 0 0;
    }

    &:hover {
      background: none;
    }
  }

  &.dark {
    .intl-tel-element {
      background-color: $color-grey-dark-2;
      color: $color-catskill !important;
      &::placeholder {
        color: $color-grey-middle-1;
      }
    }

    .country-list {
      background-color: $color-grey-dark-2;
    }
  }

  &.invalid {
    .intl-tel-element {
      background-color: $color-ferra;
    }
  }
}

.error-page-container {
  width: 100%;
  height: 100%;
  background-color: #096eb4;
  background-image: url('../../assets/svg/UN_logo.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 600px;
  min-height: 100vh;
  position: relative;
  color: #FFFFFF;
  font-weight: 600;

  .info-wrapper {
    max-width: 400px;
    position: absolute;
    top: 20%;
    padding: 0 5%;
    box-sizing: content-box;

    .logo {
      max-width: 100%;
      height: auto;
    }

    .title {
      width: 100%;
      font-size: 36px;
      line-height: 46px;
      margin-top: 10px;
    }

    .hr {
      margin: 35px 0;
      background-color: rgba(255,255,255,.2);
      border: 0;
      height: 1px;
    }

    .subtitle {
      font-size: 18px;
    }

    .buttons-container {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;

      .button {
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        border-radius: 99px;
        padding: 8px 30px 9px;
        display: inline-block;
        color: rgba(255,255,0,1);
        border: 2px solid rgba(255, 255, 0, 1);
        background-color: transparent;
      }
    }

    @media (max-width: 500px) {
      padding: 30px;

      .button {
        width: 100%;
      }
    }
  }
}
@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;

.map-field-map {
  position: relative;

  .map-field-popup {
    position: absolute;
    bottom: $margin;
    background-color: $color-white;
    border: 1px solid $color-nevada;
    padding: 5px;
    width: 60%;
    left: 20%;
    display: flex;

    .map-field-popup-icon {
      fill: $color-nevada;
      flex-shrink: 0;
    }

    .map-field-popup-content {
      color: $color-shark;
      padding: 3px 0 0 5px;

      .map-field-popup-place {
        color: $color-nevada;
        line-height: 1;

        b {
          color: $color-shark;
        }
      }
    }

    .map-field-popup-action {
      background: none;
      border: none;
      color: $color-lochmara;
      margin-left: auto;
      margin-top: auto;
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
      flex-shrink: 0;
    }

    .map-field-popup-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 10px;
      fill: $color-nevada;
      cursor: pointer;
    }
  }

  .map-field-marker-custom {
    position: relative;

    .map-field-marker-custom-count {
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: $color-white;
    }

    .map-field-marker-custom-icon {
      width: 32px;
      height: 32px;
      fill: $color-lochmara;
    }
  }

  .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    border: 1px solid $color-nevada;
    border-radius: 0;
    outline: none;
  }
}

.map-field-options {
  .map-field-option {
    display: flex;
    align-items: center;
    height: $input-height;
    background-color: $color-white;
    color: $color-shark;
    margin: calc(#{$margin} / 2) 0;

    .map-field-option-icon-wrapper {
      position: relative;
      margin: 0 0 0 5px;
      flex-shrink: 0;

      .map-field-option-count {
        position: absolute;
        top: 3px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: $color-white;
        cursor: default;
      }

      .map-field-option-icon {
        width: 32px;
        height: 32px;
        fill: $color-nevada;
      }
    }

    .map-field-option-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 15px 0 5px;
    }

    .map-field-option-delete {
      right: 10px;
      width: 10px;
      fill: $color-nevada;
      cursor: pointer;
      margin: 0 15px 0 auto;
      flex-shrink: 0;
    }
  }
}

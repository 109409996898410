.customer-projects-list-wrapper {
  background: url("../../assets/img/background2.jpg");
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  background-blend-mode: multiply !important;
  min-height: 82vh;
  color: #fff;

  padding: 100px 0 100px 100px;
  display: flex;
  flex-direction: column;
  column-gap: 20px;

  .crumb-active {
    cursor: pointer;
  }

  .ant-breadcrumb {
    margin-bottom: 40px;
  }

  .ant-breadcrumb-link {
    color: #fff;
    font-size: 14px;
  }

  .ant-breadcrumb-separator {
    color: #fff;
  }

  .table {
    padding: 10px;
    background-color: #2d3235;
  }

  .hbh-container {
    margin: 0;
    width: 90% !important;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 40px;
  }
}
.assessment-progress-step-container {

  .list-element {
    display: flex;;
    background-color: #2C3235;
    padding: 12px 16px 12px 16px;
    align-items: center;
    border-bottom: 1px solid #434A4E;

    &.summary {
      background-color: #434A4E;
    }
  }

  .estimated-cost-list-wrapper {

    .order {
      width: 20%;
    }

    .description {
      width: 60%;

      &.percentage {
        display: flex;
        column-gap: 15px;
        align-items: center;
      }

      .small-input {
        width: 50px;
      }

      &.summary {
        text-align: right;
      }
    }

    .amount {
      width: 15%;
      margin-left: auto;

      &.summary {
        text-align: right;
      }
    }


    .headers {
      width: 100%;
      background-color: #434A4E;
      padding: 12px 16px 12px 16px;
      font-size: 12px;
      font-weight: 500;

      span {
        display: inline-block;
      }

      .order {
        width: 20%;
        line-height: 1;
      }

      .description {
        width: 60%;
      }

      .amount {
        width: 20%;
        margin-right: auto;
        text-align: right;
      }
    }
  }
}
@import 'src/assets/styles/variables';

.wrapper {
  background-color: #1d2020;
  min-height: 100vh;
  color: #fff;

  .project-picture {
    width: 100%;
    max-width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .content {
    padding: 20px 150px 100px;


    @media (max-width: $breakpoint-xxl) {
      padding: 20px 25px 25px;
    }

    @media (max-width: $breakpoint-lg) {
      padding: 70px 25px 25px;
    }

    @media (max-width: $breakpoint-md) {
      padding: 75px 20px 10px;
    }


    .work-marker {
      display: flex;
      flex-direction: column;
      align-items: center;

      .circle {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .triangle {
        width: 0;
        height: 0;
        border-left: 19px solid transparent;
        border-right: 18px solid transparent;

        border-top: 20px solid #000000;
        transform: translateY(-9px);
      }
    }

    .highlight-element {
      min-height: 600px;
      padding-bottom: 30px;
      background-color: #2C3235;
      margin-top: 20px;
      width: 200px;
      position: relative;

      .chart {
        display: flex;
        margin-top: 40px;
        justify-content: center;
        height: 250px;
      }

      .chart-legend-wrapper {
        display: flex;
        margin-top: 50px;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 20px;
        padding: 0 20px;

        .chart-legend-element {
          display: flex;
          align-items: center;

          .icon {
            width: 25px !important;
            height: auto;
            margin-left: 10px;
          }

          .legend-label {
            margin-left: 10px;
          }

          .indicator {
            width: 13px;
            height: 13px;
            background-color: red;
          }
        }
      }

      .chart-control {
        display: flex;
        width: 100%;
        margin-bottom: 20px;

        .control-button {
          font-size: 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding-left: 20px;
          width: 50%;
          height: 50px;
          background-color: #434a4e;

          &.active {
            background-color: transparent;
            border-top: 2px solid #1d77c2;
          }
        }
      }

      .summary {
        padding: 0 20px;
        color: lightgray;

        .summary-text {
          white-space: break-spaces;
          margin-top: 20px;
          font-size: 14px;
          max-height: 120px;
          overflow: auto;
        }

        .summary-description {
          display: flex;
          align-items: center;
          column-gap: 10px;
          margin-top: 10px;

          .pin-icon {
            min-width: 13px;
            height: auto;
          }

          .category-icon {
            width: 25px;
            height: auto;
            margin-left: -5px;
          }
        }

        .summary-story-link {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 5px;
          text-align: center;
          margin-top: 50px;
          position: absolute;
          bottom: 20px;
          right: 30px;
        }

        .summary-title {
          font-weight: bolder;
          font-size: 20px;
          white-space: pre-wrap;
        }
      }

      .cover {
        background-size: cover;
        height: 250px;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .work-section {
      border-top: 1px solid lightgray;
      margin-top: 120px;

      .map-preview-placeholder {
        width: 25%;
        padding: 25px;
        height: auto;
        background-color: #2C3235;

        @media (max-width: $breakpoint-xl) {
          display: none;
        }
      }

      .map-category-icon {
        width: 25px;
        height: auto;
      }

      .map-preview-container {
        width: 45%;
        min-width: 500px;
        min-height: 500px;

        .map-preview {
          width: 100%;
          height: 100%;
          flex-grow: 1;
          margin-right: 0;

          canvas {
            height: 100% !important;
          }
        }
      }

      .highlight-element {
        margin-top: 0;
        width: 25%;

        @media (max-width: $breakpoint-xl) {
          width: 48%;
        }

        @media (max-width: $breakpoint-lg) {
          width: 100%;
        }

        &.highlight-chart {
          width: 28%;
          margin: 0 auto;

          @media (max-width: $breakpoint-xl) {
            width: 70%;
          }

          @media (max-width: $breakpoint-lg) {
            width: 100%;
            margin-right: 0;
          }
        }
      }

      .work-location {
        display: flex;
        flex-wrap: wrap;
        row-gap: 40px;

        @media (max-width: $breakpoint-xl) {
          .highlight-element {
            &.preview {
              display: none;
            }
          }

          .map-preview-container {
            display: none;
          }
        }
      }

      .work-label {
        font-size: 24px;
        margin: 20px 0;
      }
    }

    .highlights-section {
      border-top: 1px solid lightgray;
      margin-top: 120px;

      .highlights-label {
        font-size: 28px;
        margin: 40px 0;
      }

      .highlights-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .overview-section {
      display: flex;
      margin-top: 55px;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: $breakpoint-xl) {
        flex-direction: column;

        .text-section {
          margin-bottom: 40px;
        }

        .cover {
          width: 100%;
          max-width: unset;
        }
      }


      .text-section {
        width: 100%;
      }

      .summary-link {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }

      .country-description {
        width: 90%;
        border-top: 1px solid lightgray;
        margin-top: 40px;

        .description-label {
          font-size: 24px;
          margin: 20px 0;
        }
      }

      .agency-label {
        font-size: 20px;
        margin: 5px 0 20px;
      }

      .country-name {
        font-size: 60px;
        line-height: 1;
        margin-bottom: 20px;
      }
    }
  }
}
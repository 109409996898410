.cost-details-wrapper {

  .pagination {
    margin-top: 20px;
  }

  .ant-table-tbody tr.ant-table-row:hover > td {
    background-color: #434a4e !important;
  }

  .ant-table-row-level-0:last-child {
    &:hover {
      background: #434a4e !important;
    }
    .ant-table-cell {
      background: #434a4e;
    }
  }

  .currency-picker {
    margin-bottom: 10px;
  }
}
.assessment-mission-outcome-summary-container {
  .summary-field {
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
    margin-bottom: 10px;
  }

  .section-label {
    width: 160px;
  }
}
@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;

.upload-field {
  width: 370px;

  .upload-field-label {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 10px;
    transition: 0.2s all linear;
    height: $input-height;
    background-color: $color-grey-dark-2;
    color: $color-catskill;
    cursor: pointer;

    &:hover {
      background-color: lighten($color: $color-grey-dark-2, $amount: 3%);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  svg {
    fill: $color-catskill;
  }

  .upload-field-input {
    display: none;
  }

  .upload-field-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin: 5px 0;
    height: calc(#{$input-height} * 0.8);
    background-color: $color-nevada;

    .upload-field-file-text {
      color: $color-catskill;
      cursor: default;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
    }

    .upload-field-file-oversized {
      font-style: italic;
      font-size: 12px;
      margin-left: auto;
      padding: 0 7px;
    }

    .upload-field-file-delete {
      right: 8px;
      width: 8px;
      fill: $color-catskill;
      cursor: pointer;
      flex-shrink: 0;
    }

    &.error {
      background-color: $color-ferra;
    }
  }
}

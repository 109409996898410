@import "./antd.scss";
@import "@ui-modules/assets/styles/normalize.scss";
@import "@ui-modules/assets/styles/common.scss";
@import "src/assets/styles/variables";
@import "./fonts.scss";

.bg-image {
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ant-tooltip-arrow {
  display: none !important;
}

.engineering-tabs {
  .hbh-tab-select {
    height: 50px;
    margin-right: 0;
    border-radius: 0;
    background-color: #3f4549;
    text-align: left;
    min-width: 190px;
    opacity: 0.7;
    border: 2px solid transparent; /* Add a transparent border */
    position: relative;
    transition: border-color 0.5s ease; /* Transition the border-color property */
    cursor: pointer;
    padding: 0 0 0 20px;

    &.hbh-tab-select {
      span {
        font-weight: 400;
      }
    }

    &.hbh-tab-select__active {
      border-top: 3px solid #34abfe;
      background-color: #343536;
      opacity: 1;
    }

    &:not(:last-child) {
      border-right: 1px solid grey;
    }

    span {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-header {
  margin-bottom: 0 !important;
}

.ant-table-cell {
  &:before {
    display: none;
  }
}

.hbh-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .engineering-platform-button {
    height: 50px;
    background-color: #115287;
    border-radius: 25px;
    cursor: pointer;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hbh-status-container {
  white-space: nowrap;
}

.header-icon {
  width: 50px;
  height: auto;
}

.hbh-header-panel {
  margin-left: 30px;
}

.hbh-logo {
  width: 100%;

  svg {
    margin-right: 0 !important;
  }
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

b {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

.hbh-main {
  padding-bottom: 40px;

  &:has(div.field-assessment-container) {
    padding: unset !important;
  }
}

.hbh-service-name {
  h1 {
    letter-spacing: 0.2px !important;
    font-size: 24px !important;
  }

  h3 {
    letter-spacing: 2.5px !important;
  }
}

.to_be_processed {
  background-color: $color-red-dark-1;
  border-color: $color-red-dark-1;

  &:hover {
    background-color: lighten($color-red-dark-1, 10%);
    border-color: lighten($color-red-dark-1, 10%);
  }
}

.activity_in_progress {
  background-color: $color-sapphire;
  border-color: $color-sapphire;

  &:hover {
    background-color: lighten($color-sapphire, 10%);
    border-color: lighten($color-sapphire, 10%);
  }
}

.reviewed {
  background-color: $color-green-middle;
  border-color: $color-green-middle;

  &:hover {
    background-color: lighten($color-green-middle, 10%);
    border-color: lighten($color-green-middle, 10%);
  }
}

.project_created {
  background-color: $color-green-middle;
  border-color: $color-green-middle;

  &:hover {
    background-color: lighten($color-green-middle, 10%);
    border-color: lighten($color-green-middle, 10%);
  }
}

.under_assessment {
  background-color: $color-blue-dark;
  border-color: $color-blue-dark;

  &:hover {
    background-color: lighten($color-blue-dark, 10%);
    border-color: lighten($color-blue-dark, 10%);
  }
}

.redirected_to_co {
  background-color: $color-green-grass;
  border-color: $color-green-grass;

  &:hover {
    background-color: lighten($color-green-grass, 10%);
    border-color: lighten($color-green-grass, 10%);
  }
}

.assigned_to_tech_advisor {
  background-color: $color-green-dark;
  border-color: $color-green-dark;

  &:hover {
    background-color: lighten($color-green-dark, 10%);
    border-color: lighten($color-green-dark, 10%);
  }
}

.closed {
  background-color: $color-grey-dark-3;
  border-color: $color-grey-dark-3;

  &:hover {
    background-color: lighten($color-grey-dark-3, 10%);
    border-color: lighten($color-grey-dark-3, 10%);
  }
}

.cancelled {
  background-color: $color-grey-dark-7;
  border-color: $color-grey-dark-7;

  &:hover {
    background-color: lighten($color-grey-dark-7, 10%);
    border-color: lighten($color-grey-dark-7, 10%);
  }
}

.hbh-wrapper .booking-details-container {
  width: 75vw !important;
}

.grid-full-column {
  grid-column: 1 / -1;
}

.grid-full-height {
  height: 100% !important;
  .hbh-details-item-value {
    white-space: break-spaces !important;
  }
}

.grid-half-column {
  grid-column: auto / span 2;
}

.hbh-details-item-value {
  overflow: initial !important;
}

@media (max-width: 1140px) {
  .grid-half-column {
    grid-column: inherit;
  }
}

.hbh-details-container {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
}

.booking-details-title {
  margin-top: 40px !important;
  margin-left: 12px !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.booking-details-container {
  background-color: $color-grey-middle-2;
  border-radius: $border-radius-small;
  color: $color-white;
  padding: 20px 40px;
  margin: 0 auto 40px !important;
  min-height: 400px;
  width: 1080px !important;

  .dropdown-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    h3 {
      color: $color-white;
      font-size: 16px;
    }
  }
}

.header-name {
  width: 230px;
}

.hbh-menu-panel {
  width: 650px !important;
}

.hbh-menu-panel-content {
  row-gap: 10px;
  column-gap: 20px;
}

.hbh-wrapper {
  width: unset !important;
  margin-bottom: 0 !important;

  &:has(div.field-assessment-container) {
    margin: unset !important;

    .hbh-navigation {
      position: absolute;
    }
  }
}

.loading-site {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-wrapper {
  @media (max-width: $breakpoint-lg) {
    padding-top: 10vh;
  }
}

@import 'src/assets/styles/variables';

.project-spinner {
  .ant-spin-dot {
    position: fixed !important;
  }
}

.project-details-page-wrapper {
  .page-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
  }
}

.project-details-tabs {
  .hbh-tab-select {
    &:last-child {
      padding-right: 30px;
    }
  }
}


.project-details-wrapper {
  margin-top: 30px !important;
  color: #fff;

  @media (max-width: $breakpoint-xxl) {
    div &.booking-details-container {
      width: 100% !important;
    }
  }

  .service-request-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }

  .buttons-wrapper {
    display: flex;
    column-gap: 15px;

    &.hidden {
      display: none;
    }

    .button-edit {
      width: 100px;
      margin-top: 30px;
    }
  }

  h3 {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin: 10px 0;
  }

  .phases-label {
    display: flex;
    justify-content: space-between;

    .preview-plan-button {
      background-color: #008976;
    }
  }

  .location-section {
    margin-bottom: 40px;
  }

  .details-phases-section {
    width: 100%;
    display: block;
    margin: 0 auto;

    .ant-progress-text {
      color: #fff;
    }
  }
}

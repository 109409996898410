.repository {
  margin-top: 40px;

  .file-tree {
    margin-top: 25px;
  }

  .hbh-details-item-label {
    text-transform: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sharepoint-button {
      background-color: transparent;
      border: 1px solid #00787F;
      padding-left: 6px;

      &:hover {
        background-color: #00787F;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .link-description {
    font-size: 15px;
    font-weight: 300;
    color: #AEAEAE;

    &.empty {
      font-style: italic;
    }
  }

  .folder-icon {
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .separator {
    margin-left: 10px;
    display: inline-block;
  }

  .path-url {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .tree-content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-left: 20px;
    padding-top: 20px;

    .content-element {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
}
@import '../../../../assets/styles/variables';

.complexity-tooltip {
  .ant-tooltip-inner {
    background-color: rgba(0,0,0, 0.9) !important;
  }
}

.list-wrapper {
  display: flex;
  align-items: center;

  .content-title {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin:0;
  }

  .content-description {
    color: #fff;
    margin: 0;
  }

  .content-icon {
    align-self: flex-start;
    margin: 0 10px 0 0;
  }

  .link {
    color: #fff;
    text-decoration: underline;
  }
}

.content-list {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 30px;
  padding-left: 10px;
  max-height: 38vh;
  overflow: auto;

  @media (max-width: $breakpoint-lg) {
    row-gap: 20px;
  }
}

.guidance-intro {
  color: #fff;

  .loading-spinner {
    width: 100%;
    margin: 30px 0 20px 0;
  }

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 30px 0;
  }

  .description-wrapper {
    margin: 20px 0;
    display: flex;
    align-items: center;
    column-gap: 30px;
    row-gap: 10px;
  }

  .description-label {
    margin-right: 20px;
    display: inline-block;
    width: 80px;
  }

  .complexity-wrapper {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .complexity-value {
      display: inline-flex;
      align-items: center;
      column-gap: 20px;
      padding: 2px 20px 2px 7px;
      border-radius: 4px;
    }

    .complexity-label {
      margin-right: 20px;
    }
  }

  .hr {
    width: 95%;
    border-color: grey;
    margin: 20px 0;
  }

  .buttons-section {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-lg) {
      flex-direction: column;
      row-gap: 20px;

      .button {
        width: 100% !important;
      }
    }

    .button {
      width: 48%;
      height: 50px;
      border-radius: 5px;
    }
  }
}
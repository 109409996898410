.projects-summary {
  display: flex;
  column-gap: 30px;
  row-gap: 20px;
  flex-wrap: wrap;

  .summary-wrapper {
    background-color: #fff;
    height: 78px;
    width: auto;
    border-radius: 6px;
    padding: 10px 20px;

    .label {
      white-space: nowrap;
    }

    .bold,.value {
      font-weight: 600;
    }

    .value {
      font-size: 24px;
    }
  }
}
.update-container {

  .form-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    grid-gap: 10px;
    row-gap: 30px;

    > * {
      width: 95%;
    }

    .field-label {
      transition: 0.5s;
      &.error {
        color: #FF9494;
      }
    }

    .hbh-select-error {
      display: none !important;
    }

    .file-picker {
      grid-row-end: span 3;

      .ant-upload-list {
        color: #fff;
        padding: 20px 0;

        :hover {
          background-color: transparent;
        }

        svg {
          fill: #fff;
        }
      }

      .ant-upload.ant-upload-drag {
        background-color: transparent !important;
        border: 1px solid #ffff;
        border-radius: 8px;
        height: 250px;
        margin-top: 8px;

        .ant-upload-drag-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 90%;

          .ant-upload-text {
            color: #fff;

            &.title {
              font-size: 14px;
            }

            &.subtitle {
              font-size: 12px;
            }

            &.heading {
              text-align: left;
              margin-left: 20px;
            }
          }
        }
      }
    }

    .hbh-select {
      height: auto;
      margin-top: 5px;

      .rs__control {
        height: 35px;
        border-radius: 3px;
      }
    }

    .hbh-select-label {
      color: #fff !important;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 10px;
    }

    .checkbox {
      display: flex;
      align-items: center;
      column-gap: 10px;

      label {
        order: 3;
      }
    }

    .text-area {
      display: flex;
      flex-direction: column;
      color: #000000;
      position: relative;

      .counter {
        z-index: 10;
        color: #6E7C8B;
        font-size: 12px;
        font-style: italic;
        position: absolute;
        bottom: 5px;
        right: 5px;
      }

      &.large {
        textarea {
          height: 150px;
        }
      }

      textarea {
        position: relative;
        resize: none;
      }

      label {
        color: #fff !important;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 10px;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .button {
      height: 30px;
      min-width: 100px;
      &.cancel {
        background-color: #995701;
      }
    }
  }
}
.risk-section-container {
  color: #fff;
  max-height: 700px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 30px;

  .risk-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding: 10px;

    .tooltip-label-wrapper {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .tooltip-helper-icon {
        fill: #fff;
        width: 15px;
        height: 15px;
      }
    }

    .error {
      display: none;
    }

    .hbh-select-error {
      margin-top: 10px !important;
    }

    .text-area {
      grid-column: span 3;

      textarea {
        width: 100%;
        color: #000000;
      }
    }

    .hbh-select-label, .hbh-text-label, .hbh-date-label {
      color: #fff !important;
      white-space: nowrap;
      font-size: 14px;
    }
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 19px;

    .hr {
      width: 30%;
      height: 1px;
      border-color: gray;
      margin-bottom: 20px;
    }
  }

  .button-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;



    button {
      padding: 10px 40px;
    }
  }
}
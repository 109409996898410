@import "src/assets/styles/variables";

$margin: 20px;

.request-success {
  background: url("../../assets/img/background2.jpg");
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
  min-height: calc(100vh - 185px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  &-box {
    color: $color-catskill;
    background-color: $color-shark;
    width: 35%;
    min-width: fit-content;
    padding: calc(#{$margin} * 1.5);
  }

  &-icon {
    width: 40px;
    height: 40px;
    fill: $color-green-grass;
    margin: 0 auto $margin;
    display: block;
  }

  &-title {
    font-size: 20px;
    text-align: center;
    margin: $margin 0;
  }

  &-text {
    font-size: 16px;
    text-align: center;
  }

  &-button {
    border: 0;
    outline: 0;
    margin: $margin 0 0;
    cursor: pointer;
    background-color: $color-catskill;
    color: $color-shark;
    transition: 0.2s background-color linear;
    width: 100%;
    height: 40px;
    font-weight: 600;

    &:hover {
      background-color: $color-white;
    }
  }
}

@import 'src/assets/styles/variables';

.project-updates-wrapper {

  .spinner {
    margin: 20px auto;
    width: 100%;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;;
  }
}

.ant-image-mask-info {

  font-size: 14px;

  @media (max-width: $breakpoint-xxl) {
    font-size: 12px;
  }

  @media (max-width: $breakpoint-lg) {
    font-size: 9px;
  }
}


.status-list-wrapper {
  margin-top: 40px;
  padding-top: 30px !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.field-assessment-picker-modal {

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    align-items: flex-start;

    &.item-picker {
      margin: 0;
    }

    .button {
      flex-direction: column-reverse;
      column-gap: 10px;
      height: 38px;
      width: 150px;

      .icon {
        margin-right: 0;
      }

      &.edit-element {
        width: 250px;
        color: #1D1F20;

        background-color: #DCE0E5;

        &:hover {
          background-color: lighten(#DCE0E5, 10%);
          border-color: lighten(#DCE0E5, 10%);
        }

      }

      &.go-next {
        width: 200px;
        flex-direction: row-reverse;
        background-color: #0B77C2;

        &:hover {
          background-color: lighten(#0B77C2, 10%);
          border-color: lighten(#0B77C2, 10%);
        }
      }
    }
  }

  .picker-section {
    padding-bottom: 30px;
    border-bottom: 1px solid #696F77;

    .edit-warning-notification {
      width: 100%;
      padding: 0 16px 0 16px;
      height: 46px;
      background-color: #FFD7D9;
      border: 2px solid #FA4D56;
      color: #1D1F20;
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-size: 15px;
      font-weight: 400;

      .warning-icon {
        svg {
          width: 23px;
          height: 23px;
          fill: #FA4D56;
        }
      }
    }

    .edit-warning-message {
      margin-bottom: 32px;

      span {
        color: #FF707C;
      }
    }

    .modal-header {
      font-size: 24px;
      color: #F2F4F8;
      margin: 8px 0 40px 0;
    }
  }
}


.field-assessment-container {
  min-height: 82vh;
  background: url("../../../assets/img/background2.jpg") fixed center;
  color: #fff;
  display: flex;
  justify-content: center;

  .remove-icon {
    svg {
      width: 20px;
      height: 20px;
      fill: red;
      cursor: pointer;
    }
  }

  .header-icon {
    background-color: rgba(53, 165, 243, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    width: 40px;
    height: 40px;
    margin-right: 16px;

    svg {
      width: 21px;
      height: 21px;
      fill: #35A5F3;
    }
  }

  .content-wrapper {
    width: 80%;
    min-height: 1000px;
    background-color: rgba(29, 31, 32, 0.9);
    margin: 80px 0;
    display: flex;

    .buttons-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      align-items: flex-start;

      .button {

        flex-direction: column-reverse;
        column-gap: 10px;
        height: 38px;
        width: 150px;
        border-radius: 0;

        .icon {
          margin-right: 0;
        }

        &.publish {
          width: auto;
        }

        &.remove {
          width: auto;
        }

        &.back {
          flex-direction: row;
          border: none;
          margin-right: auto;
          text-align: left;
          width: auto;
          height: auto;
          font-size: 14px;
          font-weight: 600;

          &:hover {
            background-color: transparent;
            color: #F2F4F8;

            svg {
              fill: #F2F4F8;
            }
          }

        }

        &.go-next {
          width: 200px;
          flex-direction: row-reverse;
          background-color: #0B77C2;

          &:hover {
            background-color: lighten(#0B77C2, 10%);
            border-color: lighten(#0B77C2, 10%);
          }
        }

        &.submit {
          background-color: #1F8A3E;

          &:hover {
            background-color: lighten(#1F8A3E, 10%);
            border-color: lighten(#1F8A3E, 10%);
          }
        }

      }
    }

    .form-content {
      padding: 40px;
      width: 70%;

      .counter {
        width: 100%;
        text-align: right;
        font-size: 14px;
      }

      .numeric-input {
        height: 30px;
        font-size: 12px;
        color: #000;
      }

      .hbh-date-error {
        display: none;
      }

      .hbh-select-error {
        display: none;
      }

      .error {
        font-size: 13px;
        color: #FF9494;
        margin: 5px 0;
      }

      .form-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .completed-icon {
          fill: #42BE65;
        }

        .emails-list {
          display: flex;
          flex-direction: column;
          column-gap: 8px;

          .emails-element {
            display: flex;
            column-gap: 8px;
            align-items: center;


            .email-status {
              display: flex;
              column-gap: 8px;
              align-items: center;

              &.completed {
                color: #42BE65;
              }

              &.pending {
                color: #ECAE04;
              }
            }
          }
        }

        .badge {
          display: flex;
          align-items: center;
          column-gap: 8px;
          border: 1px solid;
          padding: 4px 12px 4px 12px;
          border-radius: 20px;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 20px;
          max-width: max-content;

          &.pending {
            border-color: #ECAE04;
            color: #ECAE04
          }

          &.completed {
            border-color: #42BE65;
            color: #42BE65;
          }
        }


        .form-title {
          display: flex;
          align-items: center;
          margin-bottom: 40px;

          .header-title {
            font-size: 24px;
          }
        }
      }
    }

    .navigation {
      width: 30%;
      padding: 40px;
    }
  }
}
@import "src/assets/styles/variables";

.mf-wrapper {
  position: relative;
  width: 100%;
  height: 40px;

  &::after {
    content: "USD";
    position: absolute;
    right: 10px;
    top: 10px;
    color: $color-nandor;
    font-size: 14px;
  }
}

.mf-field {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 6px 45px 6px 10px;
  color: $color-shark !important;
  transition: 0.3s background-color linear;
  outline: 0;
  border: 0;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: auto;
    height: 25px;
    cursor: pointer;
  }
}

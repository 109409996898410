.diamond-shape {
  width: 25px;
  height: 25px;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.diamond-icon-wrapper {
  width: 100%;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.inner-icon {
  -ms-transform: rotate(+45deg);
  -webkit-transform: rotate(+45deg);
  transform: rotate(+45deg);
}

.label {
  max-width: 90%;
  font-size: 15px;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  display: flex;
  align-items: center;

  .arrow-icon {
    fill: #fff;
    transform: rotate(-90deg);
    margin-left: 10px;
    width: 30px;
    height: 15px;
  }
}



.dashboard-overview-wrapper {

  .empty-data-label {
    margin: 100px auto 0;
    color: grey;
  }

  .country-tooltip-content {
    margin:0;
    padding: 0;
  }

  .table-widget {
    width: 100% !important;
  }

  .widget-wrapper {
    width: 100% !important;

    .country-map {
      height: 800px;
    }
  }


  .filter-dropdown-section {
    display: flex;
    column-gap: 50px;
    flex-wrap: wrap;

    .dropdown {
      width: auto;
      height: auto;

      .control-wrapper {
        height: 40px;
      }
      .control-icon {
        width: 16px;
        height: 16px;
        margin: 0 10px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
@import 'src/assets/styles/variables';

.project-summary-wrapper {
  color: #fff;
  font-weight: 400;

  .country-intro {
    padding: 24px;
    min-height: 250px;

    .project-icon {
      width: 25px;
      height: 25px;
      margin: 15px 0 0 0;

      @media (max-width: $breakpoint-lg) {
        margin-left: 0;
      }
    }
  }

  .summary-title {
    font-size: 28px;
    line-height: 1.1;
  }

  .summary-country {
    font-size: 18px;
    margin: 10px 0;
    margin-bottom: 5px;
  }

  .summary-description-label {
    font-size: 16px;
    margin: 20px 0 10px 0;
  }

  .summary-description {
    font-size: 14px;
  }
}

.project-info-wrapper {
  padding: 24px;
  background-color: #232D34;

  .information-label {
    font-size: 18px;
  }

  .projects-info-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    flex-wrap: wrap;
    row-gap: 20px;

    @media (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    .project-info-element {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      min-height: 100px;

      @media (max-width: $breakpoint-lg) {
        width: 100%;
        height: 90px;
        min-height: initial;
      }

      .info-icon {
        width: 30px;
        height: 40px;
        margin-bottom: 10px;
      }

      .infrastructure-icon {
        width: fit-content;
        height: 40px;
        margin-bottom: 12px;

        @media (max-width: $breakpoint-lg) {
          height: 30px;
          width: 50px;
          margin-left: -10px;
        }
      }

      .project-label {
        font-size: 18px;
      }

      .value {
        font-size: 14px;
      }
    }
  }
}

.story-button {
  margin: 30px 0;

  @media (max-width: $breakpoint-lg) {
    margin-top: 25px;
  }
}
.ReactCollapse--collapse {
  transition: height 500ms;
}

.picture-dragger {

  .pictures-list {
    margin-top: 20px;

    .picture-element {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .error {
        position: absolute;
        margin: 10px 0;
      }

      .picture-label, .close-button {
        margin-top: 20px;

        &.close-button {
          cursor: pointer;
        }
      }

      .field-container {
        width: 40%;
      }
    }
  }

  .dragging-area {
    display: block;
    background-color: red !important;
    width: 400px;

    .ant-upload {
      color: #fff;
      background-color: #434A4E;
      border: 1px solid #696F77;
    }

    .ant-upload-drag-container {
      display: flex !important;
      align-items: center !important;
      column-gap: 16px !important;
      justify-content: center;
    }
  }
}

.field-assessment-location-picker {

  .picker-modal-title {
    color: #F2F4F8;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .buttons-container {
    border-top: 1px solid #696F77;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {

      &.save {
        background-color: #0B77C2;
        padding: 18px 24px 18px 24px;
      }


      &.cancel {
        border: none;
        background-color: transparent;

        &:hover {
          color: #fff;
        }
      }
    }

  }

  .selected-location-label {
    margin-top: 8px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #F2F4F8;
    padding: 8px 16px 8px 8px;
    color: #2C3235;
    margin-bottom: 20px;
  }

  .location-description {
    font-size: 12px;
    color: #DCE0E5;
    margin-bottom: 10px;
  }

  .location-label {
    font-family: OpenSans-SemiBold, sans-serif;
    font-weight: 600;
    color: #F2F4F8;
    font-size: 14px;
    margin-bottom: 2px;
  }
}

.project-location-step-wrapper {
  width: 100%;

  .hidden-input {
    display: none;
    width: 0;
    height: 0;
  }

  .location-section {
    border-bottom: 1px solid #434A4E;
    padding: 20px 0;

    &:nth-child(1) {
      border-top: 1px solid #434A4E;
    }

    .location-content {
      padding: 16px 30px;

      .section-label {
        color: #F2F4F8;
        font-size: 20px;
        margin: 48px 0 16px 0;
      }

      .pictures-upload-description {
        color: #C1C7CD;
        font-size: 16px;
      }

      .selected-location-label {
        margin-top: 8px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #DCE0E5;
        padding: 8px 16px 8px 8px;
        color: #2C3235;
        margin-bottom: 20px;

        .button-edit {
          color: #0B77C2;
          display: flex;
          cursor: pointer;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          column-gap: 8px;

          svg {
            fill: #0B77C2;
          }
        }
      }

      .select-location-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 12px 12px 12px 12px;
        align-items: center;
        column-gap: 16px;
        border: 1px solid #35A5F3;
        color: #35A5F3;
        width: 200px;
        transition: 0.5s;

        &:hover {
          background-color: dodgerblue;
          color: #fff;

          svg {
            fill: #fff;
          }
        }

        svg {
          fill: #35A5F3;
        }
      }

      .location-label {
        font-family: OpenSans-SemiBold, sans-serif;
        font-weight: 600;
        color: #F2F4F8;
        font-size: 14px;
        margin: 16px 0;
      }
    }

    .location-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      column-gap: 16px;
      color: #C1C7CD;
      cursor: pointer;

      .remove-button {
        margin-left: auto;

        svg {
          width: 20px;
          height: 20px;
          fill: #C1C7CD;
        }
      }

      .arrow-icon {
        transition: 0.5s;
        &.rotated-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  .project-location-form {
    width: 100%;
  }

  .location-element-wrapper {
    width: 100%;
    background-color: red;
  }


  .add-button {
    margin-top: 20px;
    margin-right: auto;
    color: #34ABFE;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    cursor: pointer;
  }
}
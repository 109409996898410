.project-financials-wrapper {

  padding-bottom: 50px;

  .table-cell-text-overflow {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back-to-dashboard {
    margin-top: 20px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 15px;
    cursor: pointer;

    span {
      text-decoration: underline;
    }
  }

  .description {
    margin: 30px 0;
    font-size: 16px;
  }

  .title {
    font-size: 22px;
    margin: 30px 0;

    &.space-up {
      padding-top: 60px;
    }
  }
}
.assessment-checklist-summary-container {
  .summary-field {
    display: flex;
    align-items: center;
    column-gap: 40px;
    margin-bottom: 10px;

    &.section {
      align-items: flex-start;
      margin-bottom: 40px;
    }
  }

  .section-label {
    width: 200px;
  }
}
.chart {
  display: flex;
  column-gap: 50px;
  width: auto;
  flex-grow: 1;

  .chart-legend {
    min-width: 100%;
    font-size: 18px;

    .chart-label {
      font-weight: 500;;
      font-size: 18px;
    }

    .legend-wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      font-size: 16px;

      .legend-element {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .value {
          font-weight: 500;
          white-space: nowrap;
        }

        .name {
          word-break: break-all;
          text-align: left;
        }

        .legend-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          flex-shrink: 0;
        }
      }
    }
  }
}
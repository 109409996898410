.currency-picker {
  .picker-label {
    color: grey;
    font-size: 15px;
    margin-right: 5px;
  }

  .value-container {
    display: inline-flex;
    align-items: center;
    column-gap: 7px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
  }
}
@import 'src/assets/styles/variables';
.status-feed-wrapper {

  .filter-dropdown-section {
    display: flex;
    column-gap: 50px;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .clear-button {
      background-color: transparent;
      align-self: center;
    }

    .hbh-select .rs__control {
      height: 40px !important;
      min-width: 100px;
    }

    .dropdown {
      width: auto;
      height: 30px;
      color: black;

      &.select {
        min-width: 300px;
      }

      .control-wrapper {
        height: 40px;
      }
      .control-icon {
        width: 16px;
        height: 16px;
        margin: 0 10px;

        svg {
          fill: black;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .empty-info {
    text-align: center;
    color: $color-grey-middle-1;
    vertical-align: middle;
    padding-top: 100px;
  }

  .filters {
    display: flex;
    align-items: center;
  }
}
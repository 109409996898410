.phase-table {
  height: 100%;
  width: 49%;
  flex-grow: 1;

  .headers {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.36px;
    line-height: 18px;

    th {
      background-color: #D0D0D0;
      padding: 10px;
      text-align: left;
      vertical-align: middle;
    }
  }

  .ant-collapse {
    display: contents !important;
  }

  .ant-collapse-header {
    display: none !important;
  }

  .table-body {
    background-color: #fff;

    .panel {
      width: 100%;
    }

    tr {
      border-bottom: 1px solid #C9C9C9;

      &.expanded {
        border-bottom: none;
      }
    }

    td {
      padding: 10px;

      &.first-cell {
        display: flex;
        align-items: center;
        column-gap: 10px;
        border-bottom: none;
      }

      .phase-icon {
        svg {
          width: 12px;
          height: auto;
          fill: #757575;
        }
      }
    }
  }

  th,
  td {
    padding: 12px;
    text-align: left;
  }

  .first-cell {
    cursor: pointer;
  }

  .phase-icon {
    margin-right: 8px;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateX(4px);
    }
  }

  .expanded-content-row {
    overflow: hidden;
    transition: height 0.3s ease;
    display: none;

    &.expanded {
      display: table-row;
      border-bottom: 1px solid #C9C9C9 !important;
      font-size: 13px;
    }
  }

  .expanded-row {
    padding: 0;

    &.first-cell {
      padding-left: 50px;
    }
  }

  .expanded-content {
    padding: 12px;
  }
}
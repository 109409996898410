.progress-bar {
  background-color: #fff;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .progress-indicator {
    background-color: #00AC34;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
  }

  .progress-value {
    z-index: 2;
    font-size: 10px;
  }
}
.confirmation-wrapper {
  color: #fff;
  text-align: center;
  font-size: 16px;

  .title-wrapper {

    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .subtitle {
      font-weight: lighter;
      font-size: 14px;
      color: lightgray;
    }
  }

  .description {
    margin-top: 20px;

    strong {
      font-weight: bold;
    }
  }

  .button {
    width: 100%;
    margin-top: 30px;
    height: 50px;
  }

  .success-icon {
    display: block;
    margin: 0 auto 10px;
  }
}
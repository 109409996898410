@import "src/assets/styles/variables";

.country-summary-configured {
  .cover {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
  }

  .navigation-bar {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0b77c2;
    padding: 0 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: 0.2s background-color linear;

    &:hover {
      background-color: lighten($color: #0b77c2, $amount: 3%);
    }
  }

  .work-section {
    padding: 24px;

    .section-label {
      font-size: 16px;
      color: #fff;
    }

    .stats-section {
      display: flex;
      flex-direction: column;
      column-gap: 20px;
      row-gap: 5px;
      margin-top: 10px;

      .stats-element {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .name {
          font-size: 16px;
          color: lightgray;
        }

        .icon {
          width: 28px;
          height: auto;
        }

        .percentage-value {
          font-weight: bolder;
          font-size: 16px;
          color: lightgray;
        }
      }
    }
  }
}

.country-summary {
  color: #fff;
  margin-top: 5px;

  .stats-label {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .stats-description {
    margin-bottom: 30px;
    font-size: 14px;

    &.mobile {
      display: none;
    }

    @media (max-width: $breakpoint-lg) {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }
  }

  .stats-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    column-gap: 40px;

    @media (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
      row-gap: 10px;
    }

    .stats-element {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      column-gap: 10px;
      row-gap: 5px;
      font-size: 14px;

      svg {
        width: 40px;
        height: 40px;
      }

      .value {
        font-size: 18px;
      }
    }
  }
}

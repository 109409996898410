@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;

.bf-subtitle {
	display: flex;
	font-size: 22px;
	cursor: default;
	gap: calc(#{$margin} * 0.8);
	align-items: center;
	color: $color-catskill;
	margin: 0 0 calc(#{$margin} * 2);

	.bf-subtitle-icon {
		width: $input-height;
		height: $input-height;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-blue-dianne;

		svg {
			fill: $color-sky-light-1;
		}
	}
}

// Main
$color-black: #000;
$color-white: #fff;

// Grey
$color-grey-dark-1: #3f424e;
$color-grey-dark-2: #434a4e;
$color-grey-dark-3: #1e2a36;
$color-grey-dark-4: #262829;
$color-grey-dark-5: #171717;
$color-grey-dark-6: #161616;
$color-grey-dark-7: #141414;
$color-grey-light-1: #c5d3e2;
$color-grey-light-2: #e0e8ed;
$color-grey-light-3: #b8bfc4;
$color-grey-middle-1: #6e7c8b;
$color-grey-middle-2: #333536;
$color-grey-middle-3: #323536;
$color-grey-middle-4: #4e4e4e;
$color-grey-middle-5: #2a2d34;

// Other colors
$color-blue-primary: #0288ff;
$color-red-dark-1: #b3262b;
$color-blue-dark: #2b888e;
$color-green-middle: #76d48b;
$color-green-grass: #609249;
$color-green-dark: #2b680d;
$color-blue-light: #f0f7fd;
$color-blue-primary: #0288ff;
$color-blue-secondary: #3a89f7;
$color-blue-dark-1: #095d93;
$color-blue-dark-2: #075287;
$color-blue-light: #f0f7fd;
$color-green-middle: #417505;
$color-green-grass: #0dac26;
$color-cyan: #0adbbb;
$color-red-light-1: #ff6666;
$color-purpure: #982b56;
$color-sky-light-1: #52a5ff;
$color-sky-light-2: #0a93db;
$color-blue-dark-3: #0a3adb;
$color-yellow: #eac810;
$color-shark: #1d1f20;
$color-catskill: #f2f4f8;
$color-loblolly: #c1c7cd;
$color-ferra: #6a4e53;
$color-venice: #085387;
$color-lochmara: #0b77c2;
$color-geyser: #dce0e5;
$color-nevada: #696f77;
$color-oslo: #858c93;
$color-chateau: #42be65;
$color-nandor: #435350;
$color-blue-dianne: #223a4b;
$color-torch: #f50024;

// Miscellaneous

$border-radius-small: 4px;

// Breakpoints

$breakpoint-xxl: 1536px;
$breakpoint-xl: 1280px;
$breakpoint-lg: 1024px;
$breakpoint-md: 768px;
$breakpoint-sm: 640px;

.loader-container {
  width: 100%;
  height: 100vh;
  background-color: #3E4549;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-spinner {
    border: 1px solid #d6d6d6;
    background-color: white;
    border-radius: 50px;
    padding: 2%;
    width: 258px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;

// Navigation
.bf-buttons {
  display: flex;
  gap: $margin;
  margin: calc(#{$margin} * 2) auto $margin;

  .bf-button {
    height: $input-height;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: $color-white;
    border: 1px solid $color-white;
    cursor: pointer;
    transition: 0.2s background-color linear;
    font-size: 14px;
    line-height: 1;

    svg {
      fill: $color-white;
    }

    &.prev {
      background-color: transparent;
      &:hover {
        background-color: $color-grey-middle-5;
      }
    }

    &.next {
      background-color: $color-venice;
      border-color: $color-venice;
      margin-left: auto;
      &:hover {
        background-color: lighten($color-venice, 5%);
      }
    }

    &.confirm {
      background-color: $color-green-dark;
      border-color: $color-green-dark;
      margin-left: auto;
      transition: 0.2s all linear;
      &:hover {
        background-color: lighten($color-green-dark, 5%);
      }
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

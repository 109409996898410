.conversion-page-tooltip {
  .ant-tooltip-inner {
    background-color: rgba(0,0,0, 0.9);
  }
}

.conversion-wrapper {
  .page-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
  }
}

.project-form-wrapper {
  .hbh-details-container {
    grid-template-rows: auto;
  }

  .rs__placeholder {
    color: #000000;
  }

  .textarea-wrapper {

    small {
      font-size: 14px !important;
    }

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .counter {
      text-align: right;
      margin-top: 10px;
      order: 1;
      margin-left: auto;
    }
  }

  .non-edit-value {
    width: max-content;
    max-width: 100%;
    overflow: hidden;
    cursor: pointer;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    input[disabled] {
      background-color: lightgray;
    }

    .icon {
      margin-left: 10px;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    small {
      order: 3;
      width: 100%;
    }

    input {
      width: 80%;

      &.no-prefix {
        width: 100%;
      }

      &.suffix {
        flex-grow: 1;
        flex-basis: calc(100% / 3);
      }
    }

    .prefix {
      background-color: #B1B1B1;
      height: 28px;
      font-size: 12px;
      display: flex;
      color: #424242;
      width: 16%;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .item-label {
    .required-label {
      color: red;
      font-size: 10px;
      margin-left: 5px;
      position: absolute;
      top: 8px
    }
  }

  small {
    display: block;
    margin-top: 10px !important;
    font-size: 16px !important;
    color: #ff6666;
  }

  .confirm-button {
    width: auto;
    height: 30px;
    margin-left: auto;
    margin-top: 20px;
  }
}

.project-creation-success-modal {
  color: #fff;
  font-size: 16px;

  .project-button {
    height: 40px;
    width: 200px;
    margin: 45px auto 0;
    background-color: #008E1C;
    font-size: 21px;
  }

  .message-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 45px;
      height: 50px;
      margin-right: 5%;
    }

    .text {
      width: 80%;
    }
  }

  strong {
    font-weight: 800;
  }
}

.ant-tooltip-inner {
  text-align: center;
}


.section-header {
  font-size: 16px;
  color: #fff;
}

.conversion-section {
  margin: 20px 0;
}

.map-section {
  width: 100%;
  display: flex;

  .map-section-details {
    width: 100%;
  }
}

.grid-half-column {
  grid-column: auto / span 2;
}

.grid-full-column {
  grid-column: 1/1;

  .non-edit-value {
    white-space: break-spaces;
  }
}

.project-value-tooltip {
  .ant-tooltip-inner {
    width: max-content;
    white-space: nowrap;
  }
}

.text-area-large {
  height: 200px;
}

.hbh-details-item {
  position: relative;

  &.no-border {
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    .details-tooltip {
      right: 0;
    }
  }
}

.field-wrapper {
  position: relative;
}

.details-tooltip {
  width: 14px; height: 14px;
  position: absolute;
  top: 5px;
  right: 20px;
  fill: #fff;
  margin: 5px;

  &.no-location-warning {
    svg {
      fill: yellow;
      width: 20px;
      height: 20px;
    }
  }
}

.deliverable-completion-modal {
  color: #fff;
  padding-top: 15px;

  .deliverable-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .deliverable-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .hr-line {
      margin: 35px 0;
      width: 40%;
      border: none;
      height: 2px;
      background-color: #fff;
    }

    .deliverable-title {
      margin-right: 10px;
    }

    .deliverable-label {
      width: 100%;
      font-size: 15px;
      display: flex;
      row-gap: 20px;
    }
  }
  .button-section {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 30px;

    .button {
      min-width: 40%;
    }

    .confirm-button {
      background-color: #47A300;
    }
  }
}
.time-range-filter {

  .time-filter-section {
    .ant-picker-input {
      display: none;
      visibility: hidden;
    }

    .ant-picker {
      visibility: hidden;
      height: 1px;
    }
  }

  .dropdown {
    width: auto;
    height: auto;

    .control-wrapper {
      height: 40px;
    }
    .control-icon {
      width: 16px;
      height: 16px;
      margin: 0 10px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.work-section {

  display: flex;
  flex-direction: column;
  column-gap: 20px;
  border-left: 1px dotted #696F77;
  padding: 0 20px 5px;

  .add-button {
    margin-top: 20px;
    margin-left: auto;
    color: #34ABFE;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }

  .work-list-container {
    display: flex;
    width: 100%;
    column-gap: 30px;
    align-items: flex-start;
    border-bottom: 1px solid #434A4E;

    .close-icon {
      cursor: pointer;
      align-self: center;
    }

    .work-element {
      width: 60%;

      .field-label {
        white-space: nowrap;
      }
    }
  }
}

.field-container {
  margin: 24px 0;

  ul {
    list-style-position: inside;
    padding-left: 10px;
  }

  .text-area {
    width: 100%;
    height: 120px;
    color: #2C3235;
    font-size: 14px;
    line-height: 24px;
    padding: 6px;
  }

  fieldset {
    display: flex;
    column-gap: 20px;
    align-items: center;

    .radio-element label {
      display: flex;
      flex-direction: row-reverse;
      column-gap: 10px;
      margin-top: 5px;
    }
  }

  .half-width {
    width: 50%;
  }

  .quarter-width {
    width: 25%;
  }

  .field-label {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    font-family: OpenSans-SemiBold, sans-serif;
    margin-bottom: 4px;
  }

  .field-description {
    font-weight: 400;
    font-size: 12px;
    color: #C1C7CD;
    margin-bottom: 8px;
  }

  .field {
    margin-top: 4px;
  }
}


.project-overview-assessment-form {

  .project-picker-section {
    margin-bottom: 40px;

    .project-picker {
      display: flex;
      column-gap: 20px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      &.project-type {
        justify-content: flex-start;
      }

      .picker-header {
        display: flex;
        column-gap: 20px;
      }

      .picker-label {
        width: 225px;
        color: #C1C7CD;
        font-size: 14px;
        display: block;
      }

      .picker-edit-button {
        min-width: 100px;
        text-align: right;
        color: #34ABFE;
        font-size: 14px;
        cursor: pointer;
      }

      .picker-value {
        color: #F2F4F8;
        font-size: 16px;
        word-break: break-all;
        text-align: left;
      }
    }
  }
}
@import "src/assets/styles/variables";

.map-container {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;

  .ant-drawer-right {
    transition-property: none;
  }

  @media (max-width: $breakpoint-lg) {
    overflow: visible;
    z-index: 5;
    min-height: 95vh;
    background-image: url("../../assets/img/background2.jpg");
    background-size: cover;

    .ant-drawer {
      position: static !important;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-lg) {
        height: 80vh;
        overflow-y: scroll;
      }
    }

    .ant-drawer-content-wrapper {
      position: static;
    }

    .ant-drawer-content {
      height: auto;
    }

    .map {
      display: none;
    }

    .ant-drawer-mask {
      display: none;
    }

    .mapboxgl-map {
      height: 100% !important;
    }

    .mapboxgl-control-container {
      display: none;
    }

    .ant-drawer-content-wrapper {
      width: 90% !important;
      height: max-content;
      margin-top: 100px;
    }
  }

  @media (max-width: $breakpoint-lg) {
    .ant-drawer-panel-motion-right-leave {
      display: none !important;
    }

    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.mapboxgl-canvas-container {
  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.map-menu {
  width: 430px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 20%;
  margin-left: 25px;
  z-index: 2;
  transition: 1s;

  @media (max-width: $breakpoint-lg) {
    width: 70%;
    margin: 0 auto;
    left: 20px;

    &.item-selected {
      display: none;
    }
  }

  @media (max-width: $breakpoint-sm) {
    width: 100%;
    position: static;
    margin: 100px auto 40px;
  }

  &.hidden {
    transform: translateX(-85%);

    @media (max-width: $breakpoint-lg) {
      transform: translateX(0%);
    }
  }

  .toggle-menu {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: $breakpoint-lg) {
      display: none;
    }

    .menu-label {
      font-size: 14px;
      padding: 5px;
      color: #e1e1e1;
    }

    .toggle-icon {
      width: 20px;
      height: 20px;
      fill: white;
      transition: 1s;

      &.right {
        transform: rotate(-90deg);
      }

      &.left {
        transform: rotate(90deg);
      }
    }
  }

  .title-container {
    padding: 25px;

    @media (max-width: $breakpoint-lg) {
      padding: 10px;
    }
  }

  .menu-title-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;

    svg {
      fill: #36b5c5;
    }
  }

  .title-icon {
    width: 18px;
    height: 18px;
  }

  .menu-title {
    color: #36b5c5;
    font-size: 28px;

    @media (max-width: $breakpoint-lg) {
      font-size: 20px;
    }
  }

  .menu-description {
    color: #fff;
    font-size: 15px;
    padding-left: 30px;
    width: 90%;
    margin-top: 10px;

    @media (max-width: $breakpoint-lg) {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0;
      font-size: 14px;
    }
  }

  .menu-description-par {
    margin: 0 0 15px;
  }

  .block-info {
    color: $color-white;
    background-color: $color-purpure;
    padding: 10px 30px;
    font-size: 12px;
    line-height: 1.2;
  }

  .country-selection {
    padding: 25px;

    .menu-button {
      font-size: 15px;
      background-color: #0b77c2;

      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }

    .control {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 20px;
    }

    .infrastructure-filter {
      margin-top: 10px;

      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    .basic-multi-select {
      font-size: 15px !important;

      & .select__placeholder {
        font-size: 14px;
      }

      & .rs__value-container {
        font-size: 14px;
      }
    }

    .dropdown {
      width: 60%;

      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }

      & .rs__value-container {
        font-size: 13px;
      }
    }

    .line {
      background-color: #36b5c5;
      border-color: #36b5c5;
      margin-top: 10px;

      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }
  }

  .projects-stats {
    width: 100%;
    background-color: rgba(51, 51, 51, 0.5);
    display: flex;
    column-gap: 20%;
    align-items: center;
    padding: 15px 30px;
    flex-wrap: wrap;
    row-gap: 20px;

    .stats-value {
      font-size: 26px;
    }

    .stats-label {
      font-size: 12px;
    }

    .countries {
      color: #f46847;
    }

    .completed-project {
      color: #00b485;
    }

    .in-progress {
      color: #ecdfbb;
    }
  }
}

.drawer {
  z-index: 2;
  position: absolute !important;
  font-family: "Open Sans", sans-serif;
  height: 100%;

  .ant-drawer-header-title {
    flex-direction: row-reverse !important;
  }

  .drawer-title {
    font-size: 18px;
    color: #fff;
  }

  .close-icon {
    svg {
      width: 25px;
      height: 25px;
      fill: #fff;
    }
  }

  .country-projects-wrapper {
    margin-top: 40px;

    .projects-list-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      flex-wrap: wrap;
      row-gap: 40px;
      column-gap: 20px;
      cursor: pointer;
    }

    .project-element {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
}

.ant-drawer-content-wrapper {
  width: 30% !important;
  @media (max-width: 1280px) {
    width: 40% !important;
  }
}

.drawer-projects {
  .country-label {
    color: #fff;
    font-size: 18px;
    margin-left: 15px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    .project-icon {
      width: 35px;
      height: auto;
    }
  }
}

.cluster-icon {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.popover {
  .ant-popover-title {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #49a637;
    color: #fff;
    font-size: 12px;
  }

  .ant-popover-inner-content {
    padding: 0;

    .cluster-elements-list {
      display: flex;
      row-gap: 20px;
      flex-direction: column;
      padding: 10px;
    }

    .cluster-element {
      display: flex;
      column-gap: 5px;
      align-items: center;
      cursor: pointer;
    }

    .cluster-element-icon {
      width: 15px;
      height: 15px;
    }
  }
}

.project-title {
  font-size: 14px;
  text-decoration: underline;
}

@import '@assets/styles/variables';

.login-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-size: cover;
  height: 100vh;
  background-image: url("../../assets/img/login_background2.png");
  background-color: rgba(0,0,0, 0.5);
  background-blend-mode: multiply;
  color: #fff;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: auto;
  }

  .description {
    text-align: center;
    margin-bottom: 20px;
    width: 55%;

    @media (max-width: 600px) {
      width: 70%;
    }
  }

  hr {
    width: 100%;
    margin: 10px 0 30px;
  }

  .login-loading {
    margin-top: 20px;
  }

  .login-button {
    width: 360px;
    height: 40px;
    background-color: #1A6EB4;
    font-size: 20px;
    border-radius: 5px;
    margin-top: 25px;
  }
}

@import "@assets/styles/mixins";
@import "@assets/styles/variables";

.btn-requests {
  @include applyButtonColor(#1e7dd1);
}

.btn-assessment {
  @include applyButtonColor(#207523);
}

.btn-project {
  @include applyButtonColor(#487704);
}

.btn-feed {
  @include applyButtonColor(#415642);
}

.btn-assessment-report {
  @include applyButtonColor(#5b9ad5);
}

.btn-showcasing {
  @include applyButtonColor(#727514);
}

.btn-template {
  @include applyButtonColor(#416d63);
}

.hbh-menu-panel {
  max-width: 800px !important;
  width: 600px !important;
}

.hbh-menu-panel-title {
  height: 50px;
}

.hbh-menu-panel-title:before {
  content: url("../../../assets/svg/WFPLogoIcon.svg");
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 5px;
}

.btn-ffa-dashboard {
  @include applyButtonColor(#1b9847);
}

.btn-webinar {
  @include applyButtonColor(#336b73);
}

.btn-reporting {
  @include applyButtonColor(#207523);
}
.btn-ffa-assignments {
  @include applyButtonColor(#204378);
}

.btn-manual {
  @include applyButtonColor(#388b73);
}

.btn-services {
  @include applyButtonColor(#2e4d46);
}
.btn-project-dashboard {
  @include applyButtonColor(#007552);
}

.hbh-submenu-container {
  display: flex;
  flex-wrap: wrap;
  border-top-color: #808080;
  justify-content: flex-start;
  margin-top: 10px;
  padding-top: 10px !important;
  column-gap: 10px;
  row-gap: 5px;
  margin-bottom: 15px !important;

  button {
    max-width: 32%;
    width: 32% !important;
  }

  button:not(:last-child) {
    margin-right: 0 !important;
  }
}

.menu-item-hidden {
  display: none !important;
}

.hbh-menu-panel-content {
  padding-bottom: 10px !important;
}

// TO DO: Need improve MainMenu component

.hbh-menu-panel {
  padding: 0 0 20px;
}

.contact-block {
  position: relative;
  top: -30px;
  line-height: 20px;
  z-index: 9;
  color: $color-grey-light-3;
  font-style: italic;
  text-align: center;
  cursor: default;
  max-width: 60%;
  margin: 10px auto 0;

  a {
    text-decoration: underline;
    color: $color-grey-light-3;
  }
}

.entry-page-container {
  .hbh-main-menu-container .hbh-menu-panel-header .title {
    width: 100%;
  }

  .hbh-main-menu-container .hbh-menu-panel {
    overflow: visible;
  }

  .do-title-text {
    display: flex;
    align-items: center;
    justify-content: center;

    .do-title-menu {
      position: relative;
      line-height: 15px;
      margin-left: auto;

      .do-title-menu-trigger {
        width: 20px;
        height: 20px;
        fill: #fff;
        cursor: pointer;
        opacity: 0.8;
        transition: 0.2s all linear;

        &:hover {
          opacity: 1;
        }
      }

      .do-title-menu-list {
        position: absolute;
        z-index: 1;
        padding: 5px 5px 8px;
        background-color: #262829;
        border: 1px solid #171717;
        border-radius: 4px;
        box-shadow: inset 0 -1px 1px -1px #000;
        top: 30px;
        width: 180px;
        text-align: center;

        a {
          color: #fff;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

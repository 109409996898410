@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;

.infra-wrapper {
  .infra-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: $margin;
    grid-row-gap: $margin;
    margin: 0 0 $margin;

    .infra-option {
      padding: 10px 12px;
      background-color: $color-grey-dark-2;
      cursor: pointer;
      transition: 0.2s all linear;
      min-height: 315px;
      border: 2px solid $color-nevada;

      &:hover {
        background-color: lighten($color: $color-grey-dark-2, $amount: 5%);
      }

      &.checked {
        border: 2px solid $color-lochmara;
      }

      .infra-input {
        display: none;
      }

      .infra-icon {
        width: 100%;
      }

      .infra-title {
        margin: 5px 0;
        line-height: 1.2;
      }

      .infra-description {
        color: $color-geyser;
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }
}

.add-phase-wrapper {
  color: #fff;

  h4 {
    color: #fff;
  }

  .dropdown {
    margin: 20px 0;
  }

  .confirm-button {
    margin: 0 auto;
  }
}
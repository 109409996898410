.assessment-location-summary-container {
  border-bottom: 1px solid #434A4E;
  border-top: 1px solid #434A4E;
  padding: 20px 8px;

  .content-section {
    margin-top: 25px;
  }

  .pictures-list {
    list-style: none;
    padding-left: 20px; /* Adds some padding for bullet spacing */

    li {
      margin-bottom: 8px;
      &:before {
        content: "•";
        color: white;
        display: inline-block;
        width: 16px;
        margin-left: -1em;
      }
    }
  }

  .location-title {
    display: flex;
    align-items: center;
    column-gap: 16px;
    width: 100%;
    cursor: pointer;

    .section-label {
      width: auto;
    }

    .arrow-icon {
      margin-left: auto;
      transition: 0.3s;

      &.down {
        transform: rotate(180deg);
      }

      svg {
        fill: #F2F4F8;
      }
    }

    .section-label {
      font-size: 16px;
    }
  }

  .summary-field {
    display: flex;
    align-items: center;
    column-gap: 40px;
    margin-bottom: 10px;
  }

  .section-label {
    width: 160px;
  }
}
@import "src/assets/styles/variables";

.customer-area-wrapper {
  background: url("../../assets/img/background2.jpg");
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  background-blend-mode: multiply !important;
  min-height: 82vh;
  flex-wrap: wrap;
  color: #fff;

  .area-video-link-wrapper {
    margin: 40px 0 0 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    row-gap: 100px;

    @media (max-width: $breakpoint-lg) {
      row-gap: 30px;
    }
  }

  .area-header {
    @media (max-width: $breakpoint-lg) {
      text-align: center;
      margin-top: 120px;
    }

    .customer-header {
      font-size: 30px;
      margin-bottom: 5px;

      @media (max-width: $breakpoint-lg) {
        font-size: 24px;
      }
    }

    .customer-subheader {
      @media (max-width: $breakpoint-lg) {
        font-size: 16px;
      }

      font-size: 22px;
    }
  }

  @media (max-width: $breakpoint-lg) {
    min-height: 100vh;
  }

  .navigation-wrapper {
    display: flex;
    color: #fff;
    width: 80%;
    height: 350px;

    @media (max-width: $breakpoint-xl) {
      width: 90%;
    }

    @media (max-width: $breakpoint-lg) {
      margin: 120px 0;
      flex-direction: column;
      height: auto;
    }

    .navigation-element {
      padding: 50px;
      width: 33%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      cursor: pointer;
      background-color: rgba(54, 56, 60, 0.9);
      transition: 0.5s;

      svg {
        width: 32px;
        height: 32px;
        fill: #c1c7cd;
      }

      &:hover {
        background-color: rgba(76, 83, 89, 0.9);
      }

      @media (max-width: $breakpoint-lg) {
        width: 100%;
        &:not(:last-child) {
          border-bottom: 1px solid #434a4e;
        }
      }

      .progress-stats {
        display: flex;
        column-gap: 20px;
        margin-top: 20px;
        flex-wrap: wrap;
        row-gap: 20px;

        .badge {
          padding: 5px 15px;
          font-size: 14px;
          background-color: blue;
          display: flex;
          justify-content: center;
          border-radius: 20px;
          align-items: center;

          &.blue {
            background-color: #3075b7;
          }

          &.orange {
            background-color: #d39801;
          }
        }
      }

      &:not(:last-child) {
        border-right: 1px solid #434a4e;

        @media (max-width: $breakpoint-lg) {
          border-right: none;
        }
      }

      .title {
        margin: 20px 0;
        font-size: 16px;
        font-weight: bolder;
      }

      .navigation-icon {
        align-self: flex-end;
        margin-top: auto;

        svg {
          width: 24px;
          height: auto;
          fill: #2d71b9;
        }
      }

      .subtitle {
        font-size: 16px;
        color: lightgray;
      }

      .element-icon {
        svg {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

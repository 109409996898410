@import 'src/assets/styles/variables';

.dashboard-overview-wrapper {

  .demand-dashboard-page-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .reset {
    cursor: pointer;
    font-size: 14px;
    width: max-content;
    color: $color-blue-primary;
    text-decoration: underline;
    flex-shrink: 0;
    margin-bottom: 4px;
    margin-right: auto;
  }

  .summary-section {
    display: flex;
    column-gap: 30px;
    align-items: flex-start;
    height: 100%;

    .projects-summary {
      width: 200px;

      .summary-wrapper {
        width: 100%;
      }
    }
  }


  .statuses-chart {
    background-color: #fff;
    padding: 10px 20px 20px 15px;

    .ant-progress-circle-trail {
      stroke-width: 6;
    }

    .status-label {
      color: grey;
    }

    .status-element {
      padding-left: 15px;
      display: flex;
      margin: 10px 0;
      align-items: center;
      column-gap: 10px;
    }

    .ant-progress-text {
      display: none;
    }
  }

  .currency {
    font-size: 12px;
  }

  .infrastructures-chart {
    width: 150px !important;
    height: 150px !important;
  }

  .projects-chart {
    padding: 20px;
    background-color: #fff;
    height: 200px;
    border-radius: 5px;
    width: 100% !important;
    min-width: 400px;
    overflow: hidden;
    flex-grow: 1;
    @media (max-width: $breakpoint-lg) {
      flex-grow: 1;
    }

    .chart {
      height: auto;
      width: auto;
      align-items: center;

      .chart-legend {
        min-width: unset;
      }

      .legend-wrapper {
        margin-top: 10px !important;
        row-gap: 20px;
        flex-wrap: wrap;

        .legend-dot {
          width: 17px !important;
          height: 17px !important;
        }
      }
    }

    p {
      margin-bottom: 20px;
    }
  }

  .widget-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px 0;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 4%;

    .widget-wrapper {
      width: 47%;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      flex-grow: 1;
    }
  }


  .title {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  .hbh-container {
    background-color: rgba(255, 255, 255, 0.9);
    color: #000000;
  }

  .projects-stats-section {
    margin-top: 50px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    column-gap: 20px;
    width: 100%;
  }

  .infrastructure-breakdown-section {
    background-color: #fff;
    margin-top: 50px;
    width: 100%;
    padding: 15px;
  }
}

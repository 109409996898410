.financial-modal {
  .ant-modal-body {
    background-color: #3f4549;
  }

  .hbh-select-error, .hbh-date-error {
    margin-top: 5px !important;
  }

  .title {
    padding-top: 25px;
    color: #fff;
    font-size: 23px;
    margin-bottom: 40px;
    font-weight: 500;
    text-align: center;
  }

  .source-form {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;

    .buttons-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .hbh-btn-transparent:hover {
        background-color: transparent;
        color: #fff;
      }

      .hbh-btn-transparent {
        border: none;
      }

      button {
        height: 45px;
        min-width: 125px;
      }
    }

    hr {
      width: 100%;
      border: none;
      height: 1px;
      background-color: grey;
    }

    .numeric-input {
      height: 30px;
    }

    .field {
      width: 48%;

      textarea {
        width: 100%;
        height: 120px;
        padding: 5px;
      }

      &.full-column {
        width: 100%;
      }
    }

    .label-with-counter {
      color: #fff;;
      display: flex;
      justify-content: space-between;

      .error {
        color: #FF9494;
        transition: 0.5s;
      }
    }

    label {
      color: #fff;
      display: block;
      margin-bottom: 10px;
    }
  }
}

.funding-section-wrapper {
  display: flex;
  column-gap: 60px;
  align-items: flex-start;


  .ant-table-tbody tr.ant-table-row:hover > td {
    background-color: #434a4e !important;
  }

  .section-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .funding-sources-section {
    position: relative;
    width: 60%;

    .funding-budget-value {
      margin-bottom: 20px;
    }

    .pagination {
      position: absolute;
      bottom: -50px;
    }

    .actions-section {
      display: flex;
      column-gap: 24px;
      align-items: center;
    }

    .ant-table-tbody .ant-table-row-level-0:last-child {
      .ant-table-cell {
        background: #434a4e;
      }
    }

    .funding-table {
      width: 100%;
    }

    .section-content {
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      justify-content: space-between;
    }

    .new-source-button {
      font-size: 18px;
      color: #34ABFE;
      display: flex;
      font-weight: 500;
      column-gap: 10px;
      cursor: pointer;

      &:hover {
        color: lighten(#34ABFE, 10%);
      }
    }
  }

  .funding-remarks-section {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 35%;

    .remarks-box {
      width: 100%;
      flex-grow: 1;
      min-height: 200px;
      padding: 20px;
      background-color: #252829;
      border-radius: 5px;

      &.empty {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .remarks-content {
        font-size: 15px;
        margin-top: 15px;
      }

      .remarks-info {
        .author {
          font-size: 16px;
          margin-right: 30px;
          color: lightgray;
        }
        .date {
          font-size: 16px;
          color: gray;
        }
      }
    }
  }
}
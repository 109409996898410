.showcase-video-link {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.showcase-list-container {
  .buttons-container {
    display: flex;
  }

  .status-button {
    width: 100%;
    &.waiting-for-approval {
      background-color: #925C01;
    }
    &.draft {
      background-color: #253D74;
    }
  }

  .country-page-icon {
    background-color: #08286C;
  }

  .view-button {
    width: 100%;
  }

  .showcase-dropdown-filters {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .filter {
      min-width: 200px;
    }

    .reset {
      margin: 0 10px;
      background-color: transparent;
      box-shadow: none;
      border: none;
    }
  }
}

@import "src/assets/styles/variables";

$margin: 20px;
$input-height: 40px;
$field-font-size: 16px;

.bf-field {
	margin: $margin auto calc(#{$margin} * 2);
	position: relative;
	cursor: default;

	.bf-field-label {
		color: $color-catskill;
		font-size: 14px;
		margin: 0 0 5px;
	}

	.bf-field-description {
		color: $color-loblolly;
		font-size: 12px;
		margin: 0 0 5px;
	}

	.bf-field-counter {
		text-align: right;
		font-size: 10px;

		&.overfilled {
			color: $color-torch;
		}
	}

	.bf-field-error {
		color: $color-torch;
		font-size: 12px;
		position: absolute;
		bottom: -15px;
		text-wrap: nowrap;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		line-height: 1.2;
	}

	.bf-field-counter + .bf-field-error {
		bottom: 0;
	}
}

.complexity-modal-content {
  color: #fff;

  .close-button {
    width: 80%;
    margin: 40px auto 0;
    padding: 20px;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .complexity-image {
    width: 280px;
    height: 150px;
    background-repeat: no-repeat;
    background-image: url("/assets/img/complexity_speedomter.png");
    margin: 20px auto 20px;
  }

  .complexity-info {
    margin-top: 20px;
    .complexity-label {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .complexity-description {
      font-weight: 200;
      font-size: 14px;
    }
  }
}
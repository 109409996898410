@import '@assets/styles/variables';

.service-page-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
}

.hbh-container {
  width: 75vw !important;
}

.ant-table-row:hover {
  cursor: pointer;
}

.booking-list {

  .title {
    display: flex;
    align-items: center;
  }

  .title:before {
    content: url("../../../assets/svg/WFPLogoIcon.svg");
    margin-right: 20px;
    margin-top: 5px
  }

  .table {
    margin-bottom: 20px;

    .ant-table-cell {
      max-width: 200px;
    }

    .btn {
      font-size: 12px;
      height: 24px;
      width: 170px;
      position: relative;

      &:hover {
        color: $color-white;
      }
    }

    .odometer-metric {
      color: #7b7b7b;
      margin-left: 5px;
    }

    .details-link {
      text-decoration: underline;
    }
  }

  .tabs {
    .hbh-tab-select {
      background-color: darken($color-grey-dark-2, 2%);
      color: lighten($color-grey-light-1, 10%);
    }
  }

  .hbh-select-label {
    color: #fff;
  }

  .filters {
    align-items: flex-end;
    display: flex;
    margin-top: 0;
    background-color: darken($color-grey-dark-2, 2%);
    align-items: flex-end;
    padding: 15px 20px;

    .wide {
      width: 300px;
    }

    & > *:not(:last-child) {
      margin-right: 15px;
    }

    .reset {
      cursor: pointer;
      font-size: 14px;
      width: max-content;
      color: $color-blue-primary;
      text-decoration: underline;
      flex-shrink: 0;
      margin-bottom: 4px;
      margin-right: auto;
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
    }

    .btn-export-data {
      width: max-content;
      flex-shrink: 0;

      &:disabled {
        background-color: darken(#11db30, 20%);
        color: $color-white;
      }
    }
  }

  .export-button {
    background-color: $color-green-middle;
    border-color: $color-green-middle;
  }
}

@import 'src/assets/styles/variables';


.header {
  width: 100%;
  background-color: #00497C;
  cursor: pointer;
  color: #fff;
  padding: 3px 30px 3px 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: $breakpoint-lg) {
    justify-content: flex-start;
  }

  svg {
    width: 253px;
  }
}

.auth-header {
  width: 100%;
  height: 65px;
  background-color: #007DBC;
  display: flex;
  align-items: center;
  color: #fff;
  top: 0;

  .mobile-navigation-button {
    display: none;
    width: 80px;
    min-width: 80px;
    background-color: #0F497C;
    height: 100%;

    .hamburger-icon {
      margin: 0 auto;
      width: 27px;
      height: 2px;
      border-radius: 2px;
      background: #fff;
      box-shadow: 0 -6px 0 #fff, 0 6px 0 #fff;
    }

    .title {
      color: white;
      text-transform: uppercase;
      font-family: 'OpenSans-Regular', sans-serif;
      font-size: 10px;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    @media (max-width: $breakpoint-lg) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .logo {
    margin-left: 12px;
    cursor: pointer;
  }

  .title-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    .auth-title {
      font-size: 23px;
      font-weight: 600;
      letter-spacing: 3px;
      height: 30px;
    }

    .subtitle {
      letter-spacing: 3px;
      font-size: 13px;
      font-weight: 300;
    }
  }

  .user-section {
    height: 100%;
    background-color: #05375A;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding: 0 30px;

    @media (max-width: $breakpoint-lg) {
      display: none;
    }

    .right-alignment {
      margin-left: auto;
    }

  }

  .user-title {
    font-weight: 300;
  }

  .logout {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  .name {
    font-weight: 700;
  }
}

.buttons-container {
  margin: 5px 20px 5px auto;

  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}

.navigation-button {
  cursor: pointer;
  height: 45px;

  padding: 5px 20px;
  display: flex;
  align-items: center;
  border-radius: 22px;

  &.digital-office {
    background-color: #05375A;
  }

  &.my-projects {
    background-color: #1FA821;
  }

  .icon {
    fill: #fff;
    height: 28px;
    width: 28px;
    margin-right: 10px;
  }

  .button-label {
    line-height: 1.1;
  }

  .button-label p:last-child {
    font-weight: 700;
  }
}
.detail-overview-wrapper {
  margin-top: 40px;


  .header-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-section {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 16px;
      color: dodgerblue;
      cursor: pointer;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .submit-button {
    min-width: 150px;
    height: 30px;
    margin-top: 40px;
    margin-left: auto;
  }

  .overview-section {
    display: flex;
    column-gap: 50px;
    margin-top: 30px;
    padding: 10px;
    width: 100%;
    background-color: #252829;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: flex-start;

    small {
      display: block;
      font-size: 14px;
    }

    .hbh-select-error {
      margin-top: 0px !important;
    }

    .hbh-date-error {
      margin-top: 0px !important;
    }

    .overview-element {
      display: flex;
      padding: 10px;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 10px;

      &.edit-mode {
        min-width: 250px;
      }

      .overview-label {
        color: lightgray;

        &.currency {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }

      .numeric-input {
        height: 30px;
      }

      .value {
        font-size: 16px;
      }
    }
  }
}
@import 'src/assets/styles/variables';

.global-impact-preview-preview-mask {
  background-color: rgba(0,0,0, 0.8) !important;
}

.global-impact-preview-preview-switch-right, .global-impact-preview-preview-switch-left {
  .anticon {
    font-size: 30px;
  }
}

.site-container {
  min-height: 80vh;
  width: 100%;
  background-color: #444444;

  @media (max-width: $breakpoint-lg) {
    padding-top: 100px;
  }

  .story-container {
    width: 95%;
    margin: 30px auto;
    color: #fff;

    .story-label {
      margin-bottom: 10px;
      span {
        font-weight: bold;
      }
    }

    .story-cover {
      background-size: cover;
      background-position: center;
      height: 600px;
      position: relative;

      .map-button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        background-color: #1A6EB4;
        color: #fff;
        top: 25px;
        left: 25px;
        padding: 5px 15px;
        border-radius: 5px;
        cursor: pointer;

        .button-label {
          &.mobile {
            display: none;
          }

          @media (max-width: $breakpoint-lg) {
            &.mobile {
              display: block;
            }

            &.desktop {
              display: none;
            }
          }
        }

        svg {
          fill: #fff;
          transform: rotate(90deg);
          margin-right: 15px;
        }
      }
    }

    .details-container {
      background-color: #1E2020;
      display: flex;
      justify-content: space-between;

      @media (max-width: $breakpoint-lg) {
        flex-direction: column-reverse;
      }
    }

    .content {
      width: 50%;
      margin: 30px auto 50px;

      @media (max-width: $breakpoint-lg) {
        width: 90%;
      }

      .title {
        font-weight: lighter;
        font-size: 40px;
      }

      .overview {
        margin: 20px 0;

        .pictures {
          margin-top: 100px;
        }

        .overview-label {
          font-size: 22px;
        }
      }

      .description {

        img {
          height: auto !important;
        }

        * {
          color: #fff;
        }
      }
    }

    .project-overview {
      background-color: #232D34;
      padding: 20px;
      height: 100%;
      width: 35%;

      @media (max-width: $breakpoint-lg) {
        width: 100%;
      }

      .pictures {
        margin-top: 50px;
        width: 100%;

        .pictures-label {
          margin-bottom: 16px;
        }

        .ant-image {
          width: 100%;
        }
      }

      .project-stats-wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .stats-element {
          display: flex;
          align-items: center;
          column-gap: 10px;

          .stats-label {
            font-size: 12px;
          }
        }

        .icon {
          width: 30px;
          height: 30px;
        }

        .infrastructure-icon {
          height: auto;
        }
      }
    }
  }
}

.link {
  color: #fff;
  text-decoration: underline;
}

.react-datepicker__header {
  color: #fff;
}

.react-datepicker__header--time {
  border-radius: 0 !important;
}

.react-datepicker__month--disabled {
  color: grey !important;
}

.react-datepicker__month-text--keyboard-selected {
  color: #fff !important;

  &:hover {
    color: #000000 !important;
  }
}

.react-datepicker__month-text {
  color: #000000;
}

.react-datepicker-time {
  &__input-container,
  &__caption {
    color: #000;
  }
}

.ffa-details {

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    .button {
      width: 150px;
      height: 40px;
      margin-top: 30px;
    }
  }
}


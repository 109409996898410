@import "src/assets/styles/variables";

.contact-button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: block;
  border: 1px solid $color-lochmara;
  color: $color-lochmara;
  margin: 20px 0 0;
  transition: 0.2s all linear;
  border-radius: 4px;
  text-align: center;
  background: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    background-color: $color-lochmara;
    color: $color-white;
  }
}

.drawer-facility-label {
  font-size: 18px;
  color: $color-white;
  text-align: center;
  margin: 20px;
}

.drawer-facility-note {
  color: $color-white;
  margin: 20px;
}

.custom-map-menu {
  width: 515px;
}

.remove-phase-modal {
  color: #fff;
  text-align: center;
  font-size: 16px;

  .sharepoint-checkbox {
    margin: 20px 0;

    .sharepoint-label {
      margin-left: 20px;
    }
  }

  .button-group {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;

    button {
      min-width: 150px;
    }
  }
}

.phase-wrapper {
  margin-top: 30px;

  .manual-section {
    width: 100%;
    background-color: #202222;
    border: 1px solid #707070;
    border-radius: 3px;
    display: flex;
    align-items: center;
    column-gap: 50px;
    padding: 30px 25px 30px 25px;
    margin: 20px 0 40px 0;

    .icon {
      width: 400px;
    }

    .manual-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .manual-button {
        width: 240px;
        height: 30px;
        margin-top: 20px;

        .project-icon {
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .manual-description {
        white-space: pre-wrap;
      }
    }
  }

  .phase-item {
    display: flex;
    align-items: flex-start;
    white-space: break-spaces;
    margin-top: 10px;
    flex-wrap: wrap;
    position: relative;

    hr {
      width: 100%;
      border-color: #9C9C9C;
      opacity: 0.28;
    }

    .add-deliverable-button {
      border-color: #02B019;
      margin: 20px 0px 20px auto;

      &:hover {
        background-color: #02B019;
        color: white;
      }
    }

    .deliverable-content {
      width: 90%;

      .phase-description {
        margin-top: 5px;
      }

      .no-deliverables-warning {
        position: absolute;
        right: 0;

        svg {
          width: 20px;
          height: 20px;
          fill: yellow;
        }
      }
    }

    .deliverable-section {
      margin-left: 20px;
      margin-top: 20px;

      .deliverable-list {
        margin-top: 30px;
      }
    }

    .delete-icon {
      width: 20px;
      height: 20px;
      background-color: #8B0000;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer !important;
      margin-right: 30px;

      svg {
        width: 13px;
        height: 13px;
        fill: #fff;
      }
    }
  }
}

.remove-modal-confirmation {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 16px;
  row-gap: 40px;

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;

    button {
      width: 150px;
    }
  }
}

.deliverable-item {
  display: flex;
  align-items: center;
  column-gap: 10px;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .close-icon {
    fill: #FF4F4F;
    width: 15px;
    cursor: pointer;
    height: 15px;
    margin: 0 5px 0 auto;
  }

  .title {
    max-width: 70%;
  }

  .drag-icon {
    fill: #B5B5B5;
    width: 15px;
    height: 15px;
  }
}

.add-phase-button {
  margin: 10px 0;
}

.deliverable-section {
  .date-picker-section {
    display: flex;
    column-gap: 30px;
    margin: 20px 0;
  }

  .ant-picker {
    height: 20px;
    border-radius: 10px;
    margin-left: 15px;
  }
}
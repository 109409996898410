.deliverable-modal {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dropdown {
    margin: 20px 0;
  }

  .add-new-deliverable-checkbox {
    margin: 20px 0;
  }

  .checkbox-label {
    color: #fff;
    margin-top: 7px;
  }

  .ant-checkbox {
    color: #fff !important;
  }

  .button {
    height: 40px;
    width: 150px;
    background-color: #008E1C;
    margin: 20px auto;
  }
}
.assessment-checklist-step-container {

  .assessment-details-list-container {

    padding-bottom: 20px;

    .numeric-input {
      &:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }

    .hbh-text-field:disabled {
      background-color: grey;
      cursor: not-allowed;
    }

    .field-container {
      margin: 0 !important;
      .error {
        margin-left: 15px !important;
        margin-bottom: 15px;
      }
    }

    background-color: #2C3235;

    .hr {
      border: none;
      height: 1px;
      width: 100%;
      background-color: #434A4E;
    }

    .list-element {
      width: 100%;
      padding: 16px;
      min-height: 50px;
      display: flex;
      justify-content: space-between;

      .list-label {
        width: 80%;
        font-size: 16px;
      }

      .text-input {
        width: 40%;
      }

      .list-input {
        width: 11%;
      }

      fieldset {
        width: 11%;
        padding-left: 8px;
        min-width: 75px;
        display: flex;
        column-gap: 6px;
        justify-content: space-between;
        padding-right: 16px;
      }
    }



    .table-header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 40px;
      background-color: #434A4E;
      padding: 12px 16px 12px 16px;


      .headers {
        width: 11%;
        display: flex;
        column-gap: 6px;
        min-width: 75px;
        justify-content: space-between;
        align-items: center;
        padding-right: 12px;
      }
    }

  }


  .radio-element {
    &.button {
      margin-top: 8px;
      width: 50%;
      background-color: #434A4E;
      cursor: pointer;
      height: 48px;
      border: 1px solid #858C93;

      &.checked {
        background-color: #394E5D;
        border: 2px solid #35A5F3;

        .radio-input-label {
          font-weight: 600;
        }
      }

      .radio-label {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px 12px 8px;
        cursor: pointer;

        .radio-input-label {
          margin-top: 3px;
          font-size: 16px;
        }
      }

      svg {
        fill: #35A5F3;
        margin-top: 5px;
      }

      label {
        position: absolute;
        line-height: 1;
        margin-top: 20px;
        flex-direction: row;
        width: 100%;
        height: 100%;
        cursor: pointer;
        input {
          visibility: hidden;
        }
      }
    }
  }
}